/* eslint-disable react-hooks/exhaustive-deps */
import Header from "components/Headers/Header";
import ModalPerwalian from "components/Modal/Perwalian";
import useDebounce from "helpers/useDebounce";
import usePrevious from "helpers/usePrevious";
import { useEffect, useState } from "react";
import { Button, Card, CardFooter, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import Pagination from 'react-js-pagination';
import { getListKrsDosen, getListKrsMhs } from "services/krs";
import Swal from "sweetalert2";
import Loader from 'components/Loader/Loader';

const Perwalian = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState('');
  const debounceSearch = useDebounce(search, 500);

  const toggle = () => setIsOpen(!isOpen);

  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch])


  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListKrsDosen(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  const getKrsMahasiswa = async (value) => {
    setLoading(true)
    const response = await getListKrsMhs({ id: value.id, status: 'pengajuan' })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setDataModal({ data: response.results, mahasiswa: value.name, id_mahasiswa: value.id })
    }
    setLoading(false)
  }

  return (
    <>
      {loading && <Loader />}
      <ModalPerwalian
        isOpen={isOpen}
        toggle={() => toggle()}
        setLoading={setLoading}
        getData={() => getData(1, 'refresh')}
        data={dataModal}
      />
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-12 mb-3">
            <Card color="info" className="shadow p-3">
              <span className="text-white mb-2">Perhatian</span>
              <ol className="text-white mb-0">
                <li>
                  Pastikan mahasiswa memilih matakuliah sesuai semester
                </li>
                <li>
                  Apabila matakuliah yang diambil tidak sesuai harap tolak
                </li>
                <li>
                  Pastikan untuk melihat detail terkait mahasiswa dalam pengambilan matakuliah
                </li>
              </ol>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="8">
                    <h3 className="mb-0">Daftar Perwalian Mahasiswa</h3>
                  </Col>
                  <Col md="4">
                    <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">NIM</th>
                    <th scope="col">Nama Mahasiswa</th>
                    <th scope="col">Prodi</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((val, idx) => {
                      return (
                        <tr key={String(idx)}>
                          <th scope="row">
                            {((page - 1) * 15) + (idx + 1)}
                          </th>
                          <th scope="row">
                            {val.nim}
                          </th>
                          <th scope="row">
                            {val.name}
                          </th>
                          <th scope="row">
                            {val.prodi}
                          </th>
                          <th scope="row">
                            <Button color="primary" type="button" size="sm" onClick={async () => {
                              await getKrsMahasiswa(val)
                              toggle();
                            }}>
                              <i className="fa fa-gavel" />
                            </Button>
                          </th>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <th colSpan="4" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {pageInfo.totalData > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={15}
                      totalItemsCount={pageInfo.totalData}
                      pageRangeDisplayed={3}
                      onChange={handleChangePage.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Perwalian;
