import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const setLogin = async (data) => {
  const url = `${ROOT_API}auth/login`

  return callAPI({
    url,
    method: 'POST',
    data
  })
}

export const setForgotPassword = async (data) => {
  const url = `${ROOT_API}auth/forgot-password`

  return callAPI({
    url,
    method: 'POST',
    data
  })
}