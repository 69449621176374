/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select'
import { getListProvinsi, getListKabupaten, getListKecamatan, getListKelurahan } from 'services/daerahIndonesia';
import Swal from 'sweetalert2';
import { optionAgama, optionPendidikan, optionPekerjaan, optionPenghasilan } from 'helpers/optionData';
import { setRegisterMhs, setUpdateOrtuMhs, setUpdateProfileMhs } from 'services/mahasiswa';
import ModalPreview from './Preview';
import { getListProdi } from "services/prodi";
import moment from 'moment';
import Cookies from 'js-cookie';

const ModalMahasiswa = ({ isOpen, toggle, created, getData, setLoading, data, view }) => {
  const profileCookies = Cookies.get('_P01e')
  const profileDecode = JSON.parse(atob(profileCookies))

  const [mahasiswa, setMahasiswa] = useState({
    name: '',
    nisn: '',
    nim: '',
    email: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    agama: '',
    status_bekerja: '',
    nik: '',
    handphone: '',
    jenis_tinggal: '',
    alamat: '',
    rt: '',
    rw: '',
    provinsi: '',
    kabupaten: '',
    kecamatan: '',
    kelurahan: '',
    kewarganegaraan: 'Indonesia',
    kode_pos: '',
    telepon: '',
    alat_transportasi: '',
    path_ijazah: '',
    photo: ''
  });
  const [ortu, setOrtu] = useState({
    id_mahasiswa: '',
    nama_ayah: '',
    nik_ayah: '',
    tempat_lahir_ayah: '',
    tanggal_lahir_ayah: '',
    telepon_ayah: '',
    pendidikan_ayah: '',
    pekerjaan_ayah: '',
    penghasilan_ayah: '',
    nama_ibu: '',
    nik_ibu: '',
    tempat_lahir_ibu: '',
    tanggal_lahir_ibu: '',
    telepon_ibu: '',
    pendidikan_ibu: '',
    pekerjaan_ibu: '',
    penghasilan_ibu: ''
  });
  const [fileIjazah, setFileIjazah] = useState('');
  const [photo, setPhoto] = useState('');
  const [previewIjazah, setPreviewIjazah] = useState('');
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [optionProvinsi, setOptionProvinsi] = useState([]);
  const [optionKabupaten, setOptionKabupaten] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionKelurahan, setOptionKelurahan] = useState([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [optionProdi, setOptionProdi] = useState([]);
  const [prodi, setProdi] = useState('');
  const [preview, setPreview] = useState('');

  const togglePreview = () => setIsOpenPreview(!isOpenPreview);

  const getDataProvinsi = async (provinsi) => {
    const response = await getListProvinsi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProvinsi = response.provinsi.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionProvinsi(dataProvinsi)
      if (provinsi) {
        const resultProvinsi = dataProvinsi.filter(val => val.value === Number(provinsi))[0]
        return resultProvinsi
      }
    }
  }

  const getDataKabupaten = async (provinsi, kabupaten) => {
    const response = await getListKabupaten(provinsi)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKabupaten = response.kota_kabupaten.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKabupaten(dataKabupaten)
      if (kabupaten) {
        const resultKabupaten = dataKabupaten.filter(val => val.value === Number(kabupaten))[0]
        return resultKabupaten
      }
    }
  }

  const getDataKecamatan = async (kabupaten, kecamatan) => {
    const response = await getListKecamatan(kabupaten)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKecamatan = response.kecamatan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKecamatan(dataKecamatan)
      if (kecamatan) {
        const resultKecamatan = dataKecamatan.filter(val => val.value === Number(kecamatan))[0]
        return resultKecamatan
      }
    }
  }

  const getDataKelurahan = async (kecamatan, kelurahan) => {
    const response = await getListKelurahan(kecamatan)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKelurahan = response.kelurahan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKelurahan(dataKelurahan)
      if (kelurahan) {
        const resultKelurahan = dataKelurahan.filter(val => val.value === Number(kelurahan))[0]
        return resultKelurahan
      }
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', mahasiswa.name)
    formData.append('nisn', mahasiswa.nisn)
    formData.append('nim', mahasiswa.nim)
    formData.append('email', mahasiswa.email)
    formData.append('tempat_lahir', mahasiswa.tempat_lahir)
    formData.append('tanggal_lahir', mahasiswa.tanggal_lahir)
    formData.append('jenis_kelamin', mahasiswa.jenis_kelamin)
    formData.append('agama', mahasiswa.agama?.value)
    formData.append('status_bekerja', mahasiswa.status_bekerja)
    formData.append('nik', mahasiswa.nik)
    formData.append('handphone', mahasiswa.handphone)
    formData.append('jenis_tinggal', mahasiswa.jenis_tinggal)
    formData.append('telepon', mahasiswa.telepon)
    formData.append('alat_transportasi', mahasiswa.alat_transportasi)
    formData.append('alamat', mahasiswa.alamat)
    formData.append('rt', mahasiswa.rt)
    formData.append('rw', mahasiswa.rw)
    formData.append('kelurahan', mahasiswa.kelurahan?.value)
    formData.append('kecamatan', mahasiswa.kecamatan?.value)
    formData.append('kabupaten', mahasiswa.kabupaten?.value)
    formData.append('provinsi', mahasiswa.provinsi?.value)
    formData.append('kode_pos', mahasiswa.kode_pos)
    formData.append('status', true)
    formData.append('id_prodi', profileDecode?.Role?.name === 'Prodi' ? profileDecode.id_prodi : prodi?.value)
    if (!data) {
      formData.append('password', 'kmckuningan')
    }
    if (fileIjazah) {
      formData.append('path_ijazah', fileIjazah)
    }
    if (photo) {
      formData.append('photo', photo)
    }
    if (data.id) {
      formData.append('id', data.id)
    }

    let errorValidasi
    delete mahasiswa.path_ijazah
    delete mahasiswa.photo
    delete ortu.id_mahasiswa
    const validasi = Object.assign(mahasiswa)
    Object.keys(validasi).some(keys => {
      if (validasi[keys] === '') {
        if (keys.search('_') >= 0) {
          errorValidasi = `${keys.split('_').join(' ')} wajib diisi!!!`
        } else {
          errorValidasi = `${keys.charAt(0).toUpperCase() + keys.slice(1)} wajib diisi!!!`
        }
      }
      return validasi[keys] === '' || validasi[keys] === null
    })

    if (errorValidasi) {
      Swal.fire({
        icon: 'error',
        title: errorValidasi,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (data.id) {
        response = await setUpdateProfileMhs(formData)
      } else {
        response = await setRegisterMhs(formData)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        const finalResponse = await setUpdateOrtuMhs({
          ...ortu,
          id_mahasiswa: data ? data.id : response.results.id_mahasiswa,
          pendidikan_ayah: ortu.pendidikan_ayah?.value,
          pekerjaan_ayah: ortu?.pekerjaan_ayah?.value,
          penghasilan_ayah: ortu?.penghasilan_ayah?.value,
          pendidikan_ibu: ortu?.pendidikan_ibu?.value,
          pekerjaan_ibu: ortu?.pekerjaan_ibu?.value,
          penghasilan_ibu: ortu?.penghasilan_ibu?.value,
          tanggal_lahir_ayah: ortu?.tanggal_lahir_ayah === '' ? null : null,
          tanggal_lahir_ibu: ortu?.tanggal_lahir_ibu === '' ? null : null
        })
        if (finalResponse.error) {
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: response.message
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: `Mahasiswa Berhasil ${data ? 'diupdate' : 'dibuat'}!!!`
          }).then(() => {
            toggle()
            getData()
          })
        }
      }
    }
  }

  useEffect(() => {
    if (!created && data) {
      // setLevel(data.name)
    } else {
      setMahasiswa({
        name: '',
        nisn: '',
        nim: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        agama: '',
        status_bekerja: '',
        nik: '',
        handphone: '',
        jenis_tinggal: '',
        alamat: '',
        rt: '',
        rw: '',
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        kewarganegaraan: 'Indonesia',
        kode_pos: '',
        telepon: '',
        alat_transportasi: '',
        path_ijazah: ''
      })
      setOrtu({
        id_mahasiswa: '',
        nama_ayah: '',
        nik_ayah: '',
        tempat_lahir_ayah: '',
        tanggal_lahir_ayah: '',
        telepon_ayah: '',
        pendidikan_ayah: '',
        pekerjaan_ayah: '',
        penghasilan_ayah: '',
        nama_ibu: '',
        nik_ibu: '',
        tempat_lahir_ibu: '',
        tanggal_lahir_ibu: '',
        telepon_ibu: '',
        pendidikan_ibu: '',
        pekerjaan_ibu: '',
        penghasilan_ibu: ''
      })
      setFileIjazah('');
      setPhoto('');
      setPreviewIjazah('');
      setPreviewPhoto('');
      setProdi('');
    }
    initialData();
  }, [isOpen])

  useEffect(() => {
    getDataProvinsi()
    getDataProdi()
  }, [])

  const getDataProdi = async (value) => {
    const response = await getListProdi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProdi = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionProdi(dataProdi)
      if (value) {
        const valueProdi = dataProdi.filter(val => val.value === value)[0]
        setProdi(valueProdi)
      }
    }
  }

  const onChangeIjazah = (e) => {
    if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
      setFileIjazah(e.target.files[0]);
      setPreviewIjazah(URL.createObjectURL(e.target.files[0]));
    } else {
      Swal.fire('Error', 'File harus bertipe jpg/jpeg/png', 'error')
    }
  }

  // const onChangePhoto = (e) => {
  //   if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
  //     setPhoto(e.target.files[0]);
  //     setPreviewPhoto(URL.createObjectURL(e.target.files[0]));
  //   } else {
  //     Swal.fire('Error', 'File harus bertipe jpg/jpeg/png', 'error')
  //   }
  // }

  const initialData = async () => {
    let provinsi = ''
    let kabupaten = ''
    let kecamatan = ''
    let kelurahan = ''
    if (data.provinsi) {
      provinsi = await getDataProvinsi(data.provinsi)
    }
    if (data.kabupaten) {
      kabupaten = await getDataKabupaten(data.provinsi, data.kabupaten)
    }
    if (data.kecamatan) {
      kecamatan = await getDataKecamatan(data.kabupaten, data.kecamatan)
    }
    if (data.kelurahan) {
      kelurahan = await getDataKelurahan(data.kecamatan, data.kelurahan)
    }
    if (data.id_prodi) {
      await getDataProdi(data.id_prodi)
    }
    setMahasiswa({
      ...mahasiswa,
      name: data.name || '',
      nisn: data.nisn || '',
      nim: data.nim || '',
      email: data.email || '',
      tempat_lahir: data.tempat_lahir || '',
      tanggal_lahir: data.tanggal_lahir ? moment(data.tanggal_lahir).format('YYYY-MM-DD') : '',
      jenis_kelamin: data.jenis_kelamin || '',
      agama: data.agama ? optionAgama.filter(val => val.value === data.agama)[0] : '',
      status_bekerja: data.status_bekerja || '',
      nik: data.nik || '',
      handphone: data.handphone || '',
      jenis_tinggal: data.jenis_tinggal || '',
      telepon: data.telepon || '',
      alat_transportasi: data.alat_transportasi || '',
      alamat: data.alamat || '',
      rt: data.rt || '',
      rw: data.rw || '',
      provinsi,
      kabupaten,
      kecamatan,
      kelurahan,
      kewarganegaraan: 'Indonesia',
      kode_pos: data.kode_pos || '',
      path_ijazah: data.path_ijazah || '',
      photo: data.photo || '',
    })
    setOrtu({
      ...ortu,
      id_mahasiswa: data.id,
      nama_ayah: data.nama_ayah || '',
      nik_ayah: data.nik_ayah || '',
      tempat_lahir_ayah: data.tempat_lahir_ayah || '',
      tanggal_lahir_ayah: data.tanggal_lahir_ayah ? moment(data.tanggal_lahir_ayah).format('YYYY-MM-DD') : '',
      telepon_ayah: data.telepon_ayah || '',
      pendidikan_ayah: data.pendidikan_ayah ? optionPendidikan.filter(val => val.value === data.pendidikan_ayah)[0] : '',
      pekerjaan_ayah: data.pekerjaan_ayah ? optionPekerjaan.filter(val => val.value === data.pekerjaan_ayah)[0] : '',
      penghasilan_ayah: data.penghasilan_ayah ? optionPenghasilan.filter(val => val.value === data.penghasilan_ayah)[0] : '',
      nama_ibu: data.nama_ibu || '',
      nik_ibu: data.nik_ibu || '',
      tempat_lahir_ibu: data.tempat_lahir_ibu || '',
      tanggal_lahir_ibu: data.tanggal_lahir_ibu ? moment(data.tanggal_lahir_ibu).format('YYYY-MM-DD') : '',
      telepon_ibu: data.telepon_ibu || '',
      pendidikan_ibu: data.pendidikan_ibu ? optionPendidikan.filter(val => val.value === data.pendidikan_ibu)[0] : '',
      pekerjaan_ibu: data.pekerjaan_ibu ? optionPekerjaan.filter(val => val.value === data.pekerjaan_ibu)[0] : '',
      penghasilan_ibu: data.penghasilan_ibu ? optionPenghasilan.filter(val => val.value === data.penghasilan_ibu)[0] : ''
    })
  }

  const previewModal = () => {
    if (preview === 'photo') {
      return previewPhoto ? previewPhoto : `${process.env.REACT_APP_IMAGE_URL}/images/photo/${mahasiswa.photo}`
    } else {
      return previewIjazah ? previewIjazah : `${process.env.REACT_APP_IMAGE_URL}/images/ijazah/${mahasiswa.path_ijazah}`
    }
  }

  return (
    <>
      <ModalPreview
        isOpen={isOpenPreview}
        toggle={() => togglePreview()}
        data={previewModal()}
      />
      <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>{view ? 'Detail' : created ? "Buat" : "Update"} Mahasiswa</ModalHeader>
        <ModalBody className="pt-1">
          <Form onSubmit={submit}>
            <Row>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Nama</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.name}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, name: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>NIM</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.nim}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, nim: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Email</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.email}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, email: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Tempat Lahir</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.tempat_lahir}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, tempat_lahir: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Tanggal Lahir</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="date"
                        value={mahasiswa.tanggal_lahir}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, tanggal_lahir: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Jenis Kelamin</Label>
                      <div className="py-2">
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={mahasiswa.jenis_kelamin === "Laki-laki"}
                            value="Laki-laki"
                            onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_kelamin: e.target.value })}
                            disabled={view}
                          />
                          <Label check>
                            Laki-laki
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={mahasiswa.jenis_kelamin === "Perempuan"}
                            value="Perempuan"
                            onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_kelamin: e.target.value })}
                            disabled={view}
                          />
                          <Label check>
                            Perempuan
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Agama
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="agama"
                        placeholder="Select Agama"
                        value={mahasiswa.agama}
                        options={optionAgama}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, agama: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Status Bekerja</Label>
                      <div className="py-2">
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={mahasiswa.status_bekerja === "Bekerja"}
                            value="Bekerja"
                            onChange={(e) => setMahasiswa({ ...mahasiswa, status_bekerja: e.target.value })}
                            disabled={view}
                          />
                          <Label check>
                            Bekerja
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={mahasiswa.status_bekerja === "Tidak Bekerja"}
                            value="Tidak Bekerja"
                            onChange={(e) => setMahasiswa({ ...mahasiswa, status_bekerja: e.target.value })}
                            disabled={view}
                          />
                          <Label check>
                            Tidak Bekerja
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>NIK</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.nik}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, nik: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>No Handphone</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.handphone}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, handphone: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Jenis Tinggal</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.jenis_tinggal}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_tinggal: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Alat Transportasi</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.alat_transportasi}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, alat_transportasi: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>NISN</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.nisn}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, nisn: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label
                      className='required'>
                        Program Studi
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="program-studi"
                        placeholder="Select Program Studi"
                        options={optionProdi}
                        value={profileDecode?.Role?.name === 'Prodi' ? (optionProdi.length > 0 ? optionProdi.filter(val => val.value === profileDecode.id_prodi)[0] : '') : prodi}
                        onChange={(e) => setProdi(e)}
                        isDisabled={profileDecode?.Role?.name === 'Prodi' || view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Alamat</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={mahasiswa.alamat}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, alamat: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>RT</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.rt}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, rt: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>RW</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.rw}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, rw: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>Provinsi</Label>
                      <Select
                        className="form-control-alternative"
                        name="provinsi"
                        placeholder="Select Provinsi"
                        value={mahasiswa.provinsi}
                        options={optionProvinsi}
                        onChange={(e) => {
                          setMahasiswa({ ...mahasiswa, provinsi: e, kabupaten: '', kecamatan: '', kelurahan: '' })
                          getDataKabupaten(e.value)
                        }}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Kabupaten / Kota
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="kabupaten"
                        placeholder="Select Kabupaten / Kota"
                        value={mahasiswa.kabupaten}
                        isDisabled={!mahasiswa.provinsi || view}
                        options={optionKabupaten}
                        onChange={(e) => {
                          setMahasiswa({ ...mahasiswa, kabupaten: e, kecamatan: '' })
                          getDataKecamatan(e.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Kecamatan
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="kecamatan"
                        placeholder="Select Kecamatan"
                        value={mahasiswa.kecamatan}
                        isDisabled={!mahasiswa.kabupaten || view}
                        options={optionKecamatan}
                        onChange={(e) => {
                          setMahasiswa({ ...mahasiswa, kecamatan: e, kelurahan: '' })
                          getDataKelurahan(e.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>Kelurahan / Desa</Label>
                      <Select
                        className="form-control-alternative"
                        name="kelurahan"
                        placeholder="Select Kelurahan"
                        value={mahasiswa.kelurahan}
                        isDisabled={!mahasiswa.kecamatan || view}
                        options={optionKelurahan}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, kelurahan: e })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Kewarganegaraan</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        value="Indonesia"
                        disabled
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Kode Pos</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.kode_pos}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, kode_pos: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Telepon</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={mahasiswa.telepon}
                        onChange={(e) => setMahasiswa({ ...mahasiswa, telepon: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="my-4" />
            <h6 className="heading-small text-muted mb-4">
              Orang Tua
            </h6>
            <Row>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Nama Ayah</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={ortu.nama_ayah}
                        onChange={(e) => setOrtu({ ...ortu, nama_ayah: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Tempat Lahir Ayah</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={ortu.tempat_lahir_ayah}
                        onChange={(e) => setOrtu({ ...ortu, tempat_lahir_ayah: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Tanggal Lahir Ayah</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="date"
                        value={ortu.tanggal_lahir_ayah}
                        onChange={(e) => setOrtu({ ...ortu, tanggal_lahir_ayah: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>NIK Ayah</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={ortu.nik_ayah}
                        onChange={(e) => setOrtu({ ...ortu, nik_ayah: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Telepon Ayah</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={ortu.telepon_ayah}
                        onChange={(e) => setOrtu({ ...ortu, telepon_ayah: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Pendidikan Ayah</Label>
                      <Select
                        name="pendidikan"
                        className="form-control-alternative"
                        placeholder="Select Pendidikan"
                        options={optionPendidikan}
                        value={ortu.pendidikan_ayah}
                        onChange={(e) => setOrtu({ ...ortu, pendidikan_ayah: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Pekerjaan Ayah</Label>
                      <Select
                        name="pekerjaan"
                        className="form-control-alternative"
                        placeholder="Select Pekerjaan"
                        options={optionPekerjaan}
                        value={ortu.pekerjaan_ayah}
                        onChange={(e) => setOrtu({ ...ortu, pekerjaan_ayah: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Penghasilan Ayah</Label>
                      <Select
                        name="penghasilan"
                        className="form-control-alternative"
                        placeholder="Select Penghasilan"
                        options={optionPenghasilan}
                        value={ortu.penghasilan_ayah}
                        onChange={(e) => setOrtu({ ...ortu, penghasilan_ayah: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Nama Ibu</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={ortu.nama_ibu}
                        onChange={(e) => setOrtu({ ...ortu, nama_ibu: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Tempat Lahir Ibu</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={ortu.tempat_lahir_ibu}
                        onChange={(e) => setOrtu({ ...ortu, tempat_lahir_ibu: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Tanggal Lahir Ibu</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="date"
                        value={ortu.tanggal_lahir_ibu}
                        onChange={(e) => setOrtu({ ...ortu, tanggal_lahir_ibu: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>NIK Ibu</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={ortu.nik_ibu}
                        onChange={(e) => setOrtu({ ...ortu, nik_ibu: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>Telepon Ibu</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={ortu.telepon_ibu}
                        onChange={(e) => setOrtu({ ...ortu, telepon_ibu: e.target.value })}
                        disabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Pendidikan Ibu</Label>
                      <Select
                        name="pendidikan"
                        className="form-control-alternative"
                        placeholder="Select Pendidikan"
                        options={optionPendidikan}
                        value={ortu.pendidikan_ibu}
                        onChange={(e) => setOrtu({ ...ortu, pendidikan_ibu: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Pekerjaan Ibu</Label>
                      <Select
                        name="pekerjaan"
                        className="form-control-alternative"
                        placeholder="Select Pekerjaan"
                        options={optionPekerjaan}
                        value={ortu.pekerjaan_ibu}
                        onChange={(e) => setOrtu({ ...ortu, pekerjaan_ibu: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label>Penghasilan Ibu</Label>
                      <Select
                        name="penghasilan"
                        className="form-control-alternative"
                        placeholder="Select Penghasilan"
                        options={optionPenghasilan}
                        value={ortu.penghasilan_ibu}
                        onChange={(e) => setOrtu({ ...ortu, penghasilan_ibu: e })}
                        isDisabled={view}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="my-4" />
            {/* <h6 className="heading-small text-muted mb-4">
              Diskon Pembiayaan Perkuliahan
            </h6>
            <Row>
              <Col lg="12">
                <FormGroup className="form-custom">
                  <Label>Kategori Diskon Pembiayaan Perkuliahan</Label>
                  <Select
                    name="kategori-pembiayaan"
                    className="form-control-alternative"
                    placeholder="Select Kategori Diskon Pembiayaan Perkuliahan"
                    options={[
                      {
                        label: 'Gelombang 1 - 30%',
                        value: 30
                      },
                      {
                        label: 'Gelombang 2 - 20%',
                        value: 20
                      },
                      {
                        label: 'Gelombang 3 - 10%',
                        value: 10
                      },
                      {
                        label: 'Anak Karyawan - 25%',
                        value: 25
                      },
                      {
                        label: 'CSR PT - Rp.3.000.000',
                        value: 3000000
                      },
                    ]}
                    value={ortu.penghasilan_ibu}
                    onChange={(e) => setOrtu({ ...ortu, penghasilan_ibu: e })}
                    isDisabled={view}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-4" /> */}
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>Upload Photo Ijazah</Label>
                  <Input
                    type="file"
                    onChange={(e) => onChangeIjazah(e)}
                    disabled={view}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" hidden={mahasiswa?.path_ijazah ? false : (previewIjazah ? false : true)}>
                <Button type="button" onClick={() => {
                  togglePreview()
                  setPreview('ijazah')
                }}>Preview</Button>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>Upload Photo</Label>
                  <Input
                    type="file"
                    onChange={(e) => onChangePhoto(e)}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" hidden={mahasiswa?.photo ? false : (previewPhoto ? false : true)}>
                <Button type="button" onClick={() => {
                  togglePreview()
                  setPreview('photo')
                }}>Preview</Button>
              </Col>
            </Row> */}
            <Button color="primary" type="submit" hidden={view}>Simpan Perubahan</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalMahasiswa;
