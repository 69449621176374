import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}tahun-akademik`

export const getListTahunAkademik = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const getTahunAkademik = async (params) => {

  return callAPI({
    url: `${url}/akademik`,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreateTahunAkademik = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateTahunAkademik = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteTahunAkademik = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}