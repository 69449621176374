import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <>
      <div className="loading">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </>
  );
};

export default Loader;
