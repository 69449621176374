import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

class PrivateRoute extends Component {
  render () {
    const Component = this.props.privateComponent
    const token = Cookies.get('_T0123')
    const role = Cookies.get('_R8l')
    let roleDesc
    if (role) {
      roleDesc = atob(role)
    }

    return (
      <Route {...this.props} render={(props) => {
        if (token) {
          if (roleDesc === 'Administrator') {
            if (this.props.name === 'admin') {
              return <Component {...props} />
            } else {
              return <Redirect to="/admin" />
            }
          } else if (roleDesc === 'Dosen') {
            if (this.props.name === 'dosen') {
              return <Component {...props} />
            } else {
              return <Redirect to='/dosen' />
            }
          } else if (roleDesc === 'Mahasiswa') {
            if (this.props.name === 'mahasiswa') {
              return <Component {...props} />
            } else {
              return <Redirect to='/mahasiswa' />
            }
          } else if (roleDesc === 'Prodi') {
            if (this.props.name === 'prodi') {
              return <Component {...props} />
            } else {
              return <Redirect to='/prodi' />
            }
          } else if (roleDesc === 'Akademik' || roleDesc === 'Kepala Bagian Akademik' || roleDesc === 'Wakil Direktur 1') {
            if (this.props.name === 'akademik') {
              return <Component {...props} />
            } else {
              return <Redirect to='/akademik' />
            }
          } else {
            return <Redirect to='/auth' />
          }
        } else {
          return <Redirect to='/auth' />
        }
      }}></Route>
    )
  }
}

export default PrivateRoute
