/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ModalPreview = ({ isOpen, toggle, data }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalBody className="d-flex justify-content-center">
        <img alt="preview" className="img-fluid" src={data} height={400} width={400} />
      </ModalBody>
    </Modal>
  );
}

export default ModalPreview;
