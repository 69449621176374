import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const getListRole = async (params) => {
  const url = `${ROOT_API}roles`

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreateRole = async (data) => {
  const url = `${ROOT_API}roles`

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateRole = async (data, id) => {
  const url = `${ROOT_API}roles/${id}`

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteRole = async (id) => {
  const url = `${ROOT_API}roles/${id}`

  return callAPI({
    url,
    method: 'DELETE',
    token: true
  })
}