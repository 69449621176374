import HeaderMahasiswa from "components/Headers/HeaderMahasiswa";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import { getListJadwalUjianMhs } from "services/jadwalUjian";
import { getDashboardMhs } from "services/mahasiswa";
import Swal from "sweetalert2";

const Index = () => {

  const [data, setData] = useState({
    ipk: '',
    jadwal_kuliah: []
  })
  const [dataUjian, setDataUjian] = useState({
    title: '',
    jadwal_ujian: []
  })
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    const response = await getDashboardMhs()
    const res = await getListJadwalUjianMhs()

    if (response.error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results)
      setDataUjian({
        title: res.message,
        jadwal_ujian: res.results
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <HeaderMahasiswa ipk={data.ipk} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-md-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="8">
                    <h3 className="mb-0">Jadwal Kuliah</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Kode MK</th>
                    <th scope="col">Mata Kuliah</th>
                    <th scope="col">SKS</th>
                    <th scope="col">Semester</th>
                    <th scope="col">Kelas</th>
                    <th scope="col">Hari & Jam</th>
                  </tr>
                </thead>
                <tbody>
                  {data.jadwal_kuliah.length > 0 ? data.jadwal_kuliah.map((val, idx) => {
                    return (
                      <tr key={String(idx)}>
                        <th scope="row">
                          {val.kode_mk}
                        </th>
                        <th scope="row">
                          {val.mata_kuliah}
                        </th>
                        <th scope="row">
                          {val.total_sks}
                        </th>
                        <th scope="row">
                          {val.semester}
                        </th>
                        <th scope="row">
                          {val.kelas}
                        </th>
                        <th scope="row">
                          {val.hari}, {val.jam}
                        </th>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <th colSpan="6" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {dataUjian.jadwal_ujian.length > 0 && (
          <Row className="mt-4">
            <div className="col-md-12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="mt-3">
                    <Col md="8">
                      <h3 className="mb-0">Jadwal {dataUjian.title}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Kode MK</th>
                      <th scope="col">Mata Kuliah</th>
                      <th scope="col">Jenis Ujian</th>
                      <th scope="col">Program Studi</th>
                      <th scope="col">Hari, Tanggal</th>
                      <th scope="col">Jam</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataUjian.jadwal_ujian.length > 0 ? dataUjian.jadwal_ujian.map((val, idx) => {
                      return (
                        <tr key={String(idx)}>
                        <th scope="row">
                          {val.Matakuliah.kode_mk}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.mata_kuliah}
                        </th>
                        <th scope="row">
                          {val.jenis_ujian}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.Prodi.name}
                        </th>
                        <th scope="row">
                          {moment(val.tanggal_ujian).format('dddd')}, {moment(val.tanggal_ujian).format('DD-MMMM-YYYY')}
                        </th>
                        <th>
                          {val.jam_mulai} - {val.jam_selesai}
                        </th>
                      </tr>
                      )
                    }) : (
                      <tr>
                        <th colSpan="6" className="text-center">
                          Data Empty
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Index;
