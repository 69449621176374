/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, CardFooter, CardHeader, Col, CustomInput, Input, Row, Table } from 'reactstrap'
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import { getListDosenStaff, setUpdateProfileDosenStaff, getDetailDosenStaff, deleteDosenStaff } from 'services/dosenStaff';
import ModalDosenStaff from 'components/Modal/DosenStaff';

const ListDosenStaff = () => {

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [created, setCreated] = useState(true);
  const [dataModal, setDataModal] = useState('');
  const debounceSearch = useDebounce(search, 500);

  const toggle = () => setIsOpen(!isOpen);

  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch])


  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListDosenStaff(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  const changeStatus = async (value) => {
    setLoading(true)
    const formData = new FormData();
    if (value.status === false) {
      formData.append('password', 'kmckuningan')
    }
    formData.append('status', !value.status)
    formData.append('id', value.id)
    const response = await setUpdateProfileDosenStaff(formData)
    if (response.error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: `Status ${value.name} berhasil ${!value.status === true ? 'diaktifkan' : 'dinonaktifkan'}`,
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        getData()
      })
    }
  }

  const getDosenStaff = async (id) => {
    setLoading(true)
    const response = await getDetailDosenStaff({ id })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setDataModal(response.results)
    }
    setLoading(false)
  }

  const deleteData = async (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Hapus Data?',
      text: 'Apakah Anda Yakin?',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then(async (value) => {
      if (value.isConfirmed) {
        setLoading(true)
        const response = await deleteDosenStaff(id)
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
          getData(1, 'refresh')
        }
        setLoading(false)
      }
    })
  }

  return (
    <>
      {loading && <Loader />}
      <ModalDosenStaff
        toggle={() => toggle()}
        setLoading={setLoading}
        isOpen={isOpen}
        created={created}
        getData={() => getData(1, 'refresh')}
        data={dataModal}
      />
      <CardHeader className="border-0">
        <Row className="mt-3">
          <Col md="4">
            <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
          </Col>
          <Col md="8" className="d-flex justify-content-end align-items-center mt-3 mt-sm-0">
            <Button color="primary" onClick={() => {
              setCreated(true)
              setDataModal('')
              toggle()
            }} size="sm">
              <i className="fa fa-plus" />
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Nama</th>
            <th scope="col">Email</th>
            <th scope="col">Level</th>
            <th scope="col">Prodi</th>
            <th scope="col">Status</th>
            <th scope="col">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? data.map((val, idx) => {
            return (
              <tr key={String(idx)}>
                <th scope="row">
                {((page - 1) * 15) + (idx + 1)}
                </th>
                <th scope="row">
                  {val.name}
                </th>
                <th scope="row">
                  {val.email}
                </th>
                <th scope="row">
                  {val.Role.name}
                </th>
                <th scope="row">
                  {val?.Prodi?.name || '-'}
                </th>
                <th scope="row">
                  <CustomInput
                    type="switch"
                    id={val.name}
                    name="customSwitch"
                    label={val.status ? 'Aktif' : 'Belum Aktif'}
                    checked={val.status}
                    onChange={(e) => changeStatus(val)}
                  />
                </th>
                <td>
                  <Button type="button" size="sm" onClick={async () => {
                    setCreated(false);
                    await getDosenStaff(val.id)
                    toggle();
                  }}>
                    <i className="fa fa-edit" />
                  </Button>
                  <Button type="button" size="sm" onClick={() => deleteData(val.id)}>
                    <i className="fa fa-trash text-danger" />
                  </Button>
                </td>
              </tr>
            )
          }) : (
            <tr>
              <th colSpan="7" className="text-center">
                Data Empty
              </th>
            </tr>
          )}
        </tbody>
      </Table>
      <CardFooter className="py-4">
        {pageInfo.totalData > 0 && (
          <div className="d-flex justify-content-end mt-4">
            <Pagination
              activePage={page}
              itemsCountPerPage={15}
              totalItemsCount={pageInfo.totalData}
              pageRangeDisplayed={3}
              onChange={handleChangePage.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        )}
      </CardFooter>
    </>
  )
}

export default ListDosenStaff