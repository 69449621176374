import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}krs`

export const getListKrsMhs = async (params) => {

  return callAPI({
    url: `${url}/list-mhs`,
    method: 'GET',
    params,
    token: true
  })
}

export const getListKrsDosen = async (params) => {

  return callAPI({
    url: `${url}/list-pengajuan`,
    method: 'GET',
    params,
    token: true
  })
}

export const setPengajuanKrsMhs = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    token: true,
    data
  })
}

export const setKeputusanKrsMhs = async (data) => {

  return callAPI({
    url: `${url}/keputusan-krs`,
    method: 'POST',
    token: true,
    data
  })
}

export const getListPrintKrsMhs = async () => {

  return callAPI({
    url: `${url}/print-krs`,
    method: 'GET',
    token: true
  })
}
