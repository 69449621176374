import {
  CardHeader,
  CardBody,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import moment from "moment";

const ProfileMahasiswa = () => {

  const history = useHistory();

  const [tab, setTab] = useState("1");

  const profileCookies = Cookies.get('_P01e')
  const profileDecode = JSON.parse(atob(profileCookies))

  const tabPane = () => {
    return (
      <>
        <TabPane tabId="1">
          <Row className="p-2">
            <Col md={6}>
              <Row>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Nama</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.name}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Email</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.email}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">NIM</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nim}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kelas</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>MIK22A</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Status Bekerja</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.status_bekerja}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Status Kemahasiswaan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>Aktif</span>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Dosen Wali</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nama_dosen_wali}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Email Dosen Wali</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.email_dosen_wali}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Telpon Dosen Wali</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.telepon_dosen_wali}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Program Studi</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.prodi}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kepala Prodi</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kaprodi}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row className="p-2">
            <Col md={6}>
              <Row>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Tempat Lahir</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.tempat_lahir}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Tanggal Lahir</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.tanggal_lahir ? moment(profileDecode.tanggal_lahir).format('DD-MM-YYYY') : ''}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Gender</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.jenis_kelamin}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Agama</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.agama}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">NIK</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nik}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Telepon</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.telepon}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">No Handphone</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.handphone}</span>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Alamat</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.alamat}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kelurahan / Desa</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kelurahan}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kecamatan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kecamatan}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kabupaten</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kabupaten}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Provinsi</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.provinsi}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kewarganegaraan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kewarganegaraan}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Kode Pos</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.kode_pos}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row className="p-2">
            <Col md={6}>
              <Row>
                <Col md={12} xs={12} className="mb-3">
                  <span className="font-weight-bold">Ayah</span>
                  <hr className="mt-2 mb-0" />
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Nama</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nama_ayah}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Tanggal Lahir</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.tanggal_lahir_ayah ? moment(profileDecode.tanggal_lahir_ayah).format('DD-MM-YYYY') : ''}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">NIK</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nik_ayah}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Telepon</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.telepon_ayah}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Pendidikan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.pendidikan_ayah}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Pekerjaan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.pekerjaan_ayah}</span>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12} xs={12} className="mb-3">
                  <span className="font-weight-bold">Ibu</span>
                  <hr className="mt-2 mb-0" />
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Nama</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nama_ibu}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Tanggal Lahir</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.tanggal_lahir_ibu ? moment(profileDecode.tanggal_lahir_ibu).format('DD-MM-YYYY') : ''}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">NIK</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.nik_ibu}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Telepon</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.telepon_ibu}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Pendidikan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.pendidikan_ibu}</span>
                </Col>
                <Col md={5} xs={5} className="mb-3">
                  <span className="font-weight-bold">Pekerjaan</span>
                </Col>
                <Col md={7} xs={7} className="mb-3">
                  <span>{profileDecode.pekerjaan_ayah}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
      </>
    );
  };

  return (
    <>
      <CardHeader className="bg-white border-0">
        <Nav>
          <NavItem>
            <NavLink active={tab === "1"} onClick={() => setTab("1")}>
              <i className="fas fa-school mr-2" />
              Data Akademik
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={tab === "2"} onClick={() => setTab("2")}>
              <i className="fa fa-user mr-2" />
              Data Diri
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={tab === "3"} onClick={() => setTab("3")}>
              <i className="fas fa-user-friends mr-2" />
              Orang Tua dan Wali
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody>
        <TabContent activeTab={tab}>
          {tabPane()}
        </TabContent>
        <Button color="primary" type="button" onClick={() => history.push('/mahasiswa/user-profile/sunting')}>Sunting</Button>
        <Button color="danger" type="button" onClick={() => history.push('/mahasiswa/user-profile/change-password')}>Ganti Password</Button>
      </CardBody>
    </>
  );
};

export default ProfileMahasiswa;
