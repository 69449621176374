/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateAktivasi } from 'services/aktivasi';
import { setUpdateAktivasi } from 'services/aktivasi';
import moment from 'moment';

const ModalAktivasi = ({ isOpen, toggle, created, getData, setLoading, data, listProdi }) => {

    const [mulaiDari, setMulaiDari] = useState('');
    const [sampai, setSampai] = useState('');
    const [aktivasi, setAktivasi] = useState('');
    const [flag, setFlag] = useState('');

    useEffect(() => {
        if (!created && data) {
            setMulaiDari(data.mulai_dari ? moment(data.mulai_dari).format('YYYY-MM-DD') : '')
            setAktivasi(data.name)
            setSampai(data.sampai ? moment(data.sampai).format('YYYY-MM-DD') : '')
            setFlag(String(data.flag))
        } else {
            setMulaiDari('');
            setAktivasi('');
            setSampai('');
            setFlag('');
        }
    }, [isOpen])

    const submit = async (e) => {
        e.preventDefault()

        if (!mulaiDari || !aktivasi || !sampai || !flag) {
            Swal.fire({
                icon: 'error',
                title: 'Isian Form harus diisi semua!!!',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            const payload = {
                name: aktivasi,
                mulai_dari: mulaiDari,
                sampai,
                flag: Boolean(flag),
            }
            setLoading(true)
            let response
            if (created) {
                response = await setCreateAktivasi(payload)
            } else {
                response = await setUpdateAktivasi(payload, data.id)
            }
            if (response.error) {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: response.message
                })
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: response.message
                }).then(() => {
                    toggle()
                    getData()
                })
            }
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Aktivasi</ModalHeader>
            <ModalBody className="pt-1">
                <Form onSubmit={submit}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className='required'>Nama Aktivasi</Label>
                                <Input
                                    type="text"
                                    placeholder="Drop some text here..."
                                    value={aktivasi}
                                    onChange={(e) => setAktivasi(e.target.value)}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <Label className='required'>Status</Label>
                                <div className="py-2">
                                    <FormGroup
                                        check
                                        inline
                                    >
                                        <Input
                                            type="radio"
                                            checked={flag === 'true'}
                                            value={'true'}
                                            onChange={(e) => setFlag(e.target.value)}
                                        />
                                        <Label check>
                                            Aktif
                                        </Label>
                                    </FormGroup>
                                    <FormGroup
                                        check
                                        inline
                                    >
                                        <Input
                                            type="radio"
                                            checked={flag === 'false'}
                                            value={'false'}
                                            onChange={(e) => setFlag(e.target.value)}
                                        />
                                        <Label check>
                                            Tidak Aktif
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className='required'>Mulai Dari</Label>
                                <Input
                                    type="date"
                                    placeholder="Drop some text here..."
                                    value={mulaiDari}
                                    onChange={(e) => setMulaiDari(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className='required'>Sampai Dari</Label>
                                <Input
                                    type="date"
                                    placeholder="Drop some text here..."
                                    value={sampai}
                                    onChange={(e) => setSampai(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color="primary" type="submit">Simpan Data</Button>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ModalAktivasi;
