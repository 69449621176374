import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const getListDosenStaff = async (params) => {

  return callAPI({
    url: `${ROOT_API}dosen-staff`,
    method: 'GET',
    params,
    token: true
  })
}

export const getDetailDosenStaff = async (data) => {

  return callAPI({
    url: `${ROOT_API}dosen-staff/detail`,
    method: 'POST',
    data,
    token: true
  })
}

export const setRegisterDosenStaff = async (data) => {

  return callAPI({
    url: `${ROOT_API}dosen-staff/register`,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateProfileDosenStaff = async (data) => {

  return callAPI({
    url: `${ROOT_API}dosen-staff/update`,
    method: 'PUT',
    token: true,
    data
  })
}

export const deleteDosenStaff = async (id) => {

  return callAPI({
    url: `${ROOT_API}dosen-staff/delete/${id}`,
    method: 'DELETE',
    token: true
  })
}


export const getJadwalMengajar = async () => {

  return callAPI({
    url: `${ROOT_API}dosen-staff/jadwal-mengajar`,
    method: 'GET',
    token: true
  })
}
