import Index from "views/dosen/Index";
import Profile from "views/dosen/Profile";
import MataKuliah from "views/prodi/MataKuliah";
import JadwalKuliah from "views/prodi/JadwalKuliah";
import Mahasiswa from "views/prodi/Mahasiswa";
import DosenWali from "views/prodi/DosenWali";
import Perwalian from "views/dosen/Perwalian";
import Penilaian from "layouts/PenilaianMahasiswa";

const routesProdi = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/prodi"
  },
  {
    path: "/mahasiswa",
    name: "Mahasiswa",
    icon: "fa fa-users text-primary",
    component: Mahasiswa,
    layout: "/prodi"
  },
  {
    path: "/dosen-wali",
    name: "Dosen Wali",
    icon: "ni ni-badge text-primary",
    component: DosenWali,
    layout: "/prodi"
  },
  {
    path: "/perwalian",
    name: "Perwalian",
    icon: "fas fa-book-reader text-primary",
    component: Perwalian,
    layout: "/prodi"
  },
  {
    path: "/mata-kuliah",
    name: "Mata Kuliah",
    icon: "ni ni-books text-primary",
    component: MataKuliah,
    layout: "/prodi"
  },
  {
    path: "/penilaian-mahasiswa",
    name: "Penilaian Mahasiswa",
    icon: "fa fa-layer-group text-primary",
    component: Penilaian,
    layout: "/prodi"
  },
  {
    path: "/jadwal-kuliah",
    name: "Jadwal Kuliah",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: JadwalKuliah,
    layout: "/prodi"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/prodi"
  }
];
export default routesProdi;
