import Index from "views/mahasiswa/Index";
import Profile from "layouts/ProfileMahasiswa";
import Khs from "views/mahasiswa/Khs";
import Krs from "views/mahasiswa/Krs";
import Kurikulum from "views/mahasiswa/Kurikulum";

const routesMahasiswa = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/mahasiswa"
  },
  {
    path: "/khs",
    name: "KHS",
    icon: "fas fa-book-reader text-primary",
    component: Khs,
    layout: "/mahasiswa"
  },
  {
    path: "/krs",
    name: "KRS",
    icon: "fa fa-layer-group text-primary",
    component: Krs,
    layout: "/mahasiswa"
  },
  {
    path: "/kurikulum",
    name: "Kurikulum",
    icon: "fas fa-book-open text-primary",
    component: Kurikulum,
    layout: "/mahasiswa"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/mahasiswa"
  }
];
export default routesMahasiswa;
