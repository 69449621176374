import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}kelas`

export const getListKelas = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreateKelas = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateKelas = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteKelas = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}