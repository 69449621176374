/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  CardFooter,
  Button,
  Input,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Header from "components/Headers/Header";
import Select from "react-select";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import { getListProdi } from "services/prodi";
import { getListDosenStaff } from "services/dosenStaff";
import { getListJadwalKuliah } from "services/jadwalkuliah";
import { deleteJadwalKuliah } from "services/jadwalkuliah";
import { getListKelas } from "services/kelas";
import ModalJadwalKuliah from "components/Modal/JadwalKuliah";


const JadwalKuliah = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [created, setCreated] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState('');
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionDosen, setOptionDosen] = useState([]);
  const [optionKelas, setOptionKelas] = useState([]);
  const [prodi, setProdi] = useState('');
  const debounceSearch = useDebounce(search, 500);

  const toggle = () => setIsOpen(!isOpen);

  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch, prodi])

  useEffect(() => {
    getDataProdi()
  }, [])

  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search,
      prodi: prodi?.value
    }
    const response = await getListJadwalKuliah(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const deleteData = async (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Hapus Data?',
      text: 'Apakah Anda Yakin?',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then(async (value) => {
      if (value.isConfirmed) {
        setLoading(true)
        const response = await deleteJadwalKuliah(id)
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
          getData(1, 'refresh')
        }
        setLoading(false)
      }
    })
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  const getDataProdi = async () => {
    const response = await getListProdi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProdi = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionProdi(dataProdi)
    }
  }

  const getDataDosen = async () => {
    const response = await getListDosenStaff({ dosen: true })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataDosen = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionDosen(dataDosen)
    }
  }

  const getDataKelas = async () => {
    const response = await getListKelas({ kelas: true })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKelas = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionKelas(dataKelas)
    }
  }

  return (
    <>
      {loading && <Loader />}
      <ModalJadwalKuliah
        toggle={() => toggle()}
        setLoading={setLoading}
        isOpen={isOpen}
        created={created}
        getData={() => getData(1, 'refresh')}
        data={dataModal}
        listDosen={optionDosen}
        listKelas={optionKelas}
        listProdi={optionProdi}
      />
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col md={5}>
            <FormGroup row className="form-custom">
              <Label
                for="exampleEmail"
                sm={4}
                className="text-white"
              >
                Program Studi
              </Label>
              <Col sm={8}>
                <Select
                  className="form-control-alternative"
                  name="program-studi"
                  placeholder="Select Program Studi"
                  options={optionProdi}
                  defaultValue={prodi}
                  onChange={(e) => setProdi(e)}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={7} />
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Daftar Jadwal Kuliah</h3>
                <Row className="mt-3">
                  <Col md="4">
                    <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                  </Col>
                  <Col md="8" className="d-flex justify-content-end align-items-center mt-3 mt-sm-0">
                    <Button color="primary" onClick={async () => {
                      await getDataKelas()
                      await getDataDosen()
                      setCreated(true)
                      toggle()
                    }} size="sm">
                      <i className="fa fa-plus" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Dosen Pengajar</th>
                    <th scope="col">Kode MK</th>
                    <th scope="col">Mata Kuliah</th>
                    <th scope="col">Semester</th>
                    <th scope="col">Program Studi</th>
                    <th scope="col">Hari, Jam</th>
                    <th scope="col">Tahun Akademik</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? data.map((val, idx) => {
                    return (
                      <tr key={String(idx)}>
                        <th scope="row">
                          {val.DosenStaff.name}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.kode_mk}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.mata_kuliah}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.semester}
                        </th>
                        <th scope="row">
                          {val.Matakuliah.Prodi.name}
                        </th>
                        <th scope="row">
                          {val.hari}, {val.jam}
                        </th>
                        <th scope="row">
                          {val.tahun_akademik}
                        </th>
                        <td>
                          <Button type="button" size="sm" onClick={async () => {
                            await getDataKelas()
                            await getDataDosen()
                            setCreated(false);
                            setDataModal(val);
                            toggle();
                          }}>
                            <i className="fa fa-edit" />
                          </Button>
                          <Button type="button" size="sm" onClick={() => deleteData(val.id)}>
                            <i className="fa fa-trash text-danger" />
                          </Button>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <th colSpan="8" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {pageInfo.totalData > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={15}
                      totalItemsCount={pageInfo.totalData}
                      pageRangeDisplayed={3}
                      onChange={handleChangePage.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default JadwalKuliah;
