/* eslint-disable react-hooks/exhaustive-deps */
import Header from "components/Headers/Header";
import { useEffect, useState } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import Pagination from 'react-js-pagination';
import { getListMatkul } from "services/matakuliah";
import Swal from "sweetalert2";
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';

const Kurikulum = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const debounceSearch = useDebounce(search, 500);
  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch])


  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListMatkul(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  return (
    <>
      {loading && <Loader />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="8">
                    <h3 className="mb-0">Daftar Mata Kuliah</h3>
                  </Col>
                  <Col md="4">
                    <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Kode MK</th>
                    <th scope="col">Mata Kuliah</th>
                    <th scope="col">SKS</th>
                    <th scope="col">Semester</th>
                    <th scope="col">Jenis Matkul</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? data.map((val, idx) => {
                    return (
                      <tr key={String(idx)}>
                        <th scope="row">
                          {val.kode_mk}
                        </th>
                        <th scope="row">
                          {val.mata_kuliah}
                        </th>
                        <th scope="row">
                          {val.total_sks}
                        </th>
                        <th scope="row">
                          {val.semester}
                        </th>
                        <th scope="row">
                          {val.jenis_matkul}
                        </th>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <th colSpan="6" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {pageInfo.totalData > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={15}
                      totalItemsCount={pageInfo.totalData}
                      pageRangeDisplayed={3}
                      onChange={handleChangePage.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Kurikulum;
