/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardFooter, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap';
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import { getListMhs } from 'services/mahasiswa';
import Header from 'components/Headers/Header';
import PDFKhs from 'components/PDF/Khs';
import { getListKhsMhsAdmin } from "services/khs";
import { useReactToPrint } from 'react-to-print';

const Khs = () => {

  const [data, setData] = useState([]);
  const [dataMhs, setDataMhs] = useState({});
  const [dataPrint, setDataPrint] = useState([]);
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [ipk, setIpk] = useState('');
  const [sks, setSks] = useState('');
  const [wadir, setWadir] = useState('');
  const [akademik, setAkademik] = useState('');
  const [kaprodi, setKaprodi] = useState({
    name: '',
    prodi: ''
  });
  const ref = useRef();
  const debounceSearch = useDebounce(search, 500);

  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch])


  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListMhs(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  const getPrintKhs = async (value) => {
    setLoading(true)
    const params = {
      idMahasiswa: value.id,
      prodi: value.id_prodi
    }
    const response = await getListKhsMhsAdmin(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (response.results) {
        setDataMhs(value)
        setDataPrint(response?.results?.result)
        setIpk(response?.results?.ipk)
        setKaprodi(response?.results?.kaprodi)
        setWadir(response?.results?.wadir?.name || '')
        setAkademik(response?.results?.akademik?.name || '')
        setSks(response?.results?.total_sks)
        handlePrint()
      }
    }
    setLoading(false)
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: 'KHS',
  })

  return (
    <>
      <PDFKhs ref={ref} profile={{ nim: dataMhs.nim, name: dataMhs.name, prodi: dataMhs?.Prodi?.name }} data={dataPrint} semester={dataMhs.semester} ipk={ipk} kaprodi={kaprodi} sks={sks} wadir={wadir} akademik={akademik} />
      {loading && <Loader />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="4">
                    <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Prodi</th>
                    <th scope="col">Semester</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? data.map((val, idx) => {
                    return (
                      <tr key={String(idx)}>
                        <th scope="row">
                          {((page - 1) * 15) + (idx + 1)}
                        </th>
                        <th scope="row">
                          {val.name}
                        </th>
                        <th scope="row">
                          {val.Prodi.name}
                        </th>
                        <th scope="row">
                          {val.semester}
                        </th>
                        <td>
                          <Button type="button" size="sm" onClick={() => getPrintKhs(val)}>
                            Cetak KHS
                          </Button>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <th colSpan="6" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {pageInfo.totalData > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={15}
                      totalItemsCount={pageInfo.totalData}
                      pageRangeDisplayed={3}
                      onChange={handleChangePage.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Khs