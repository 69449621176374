/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Button, Card, CardFooter, CardHeader, Col, Input, Row, Table } from "reactstrap";
import useDebounce from 'helpers/useDebounce';
import usePrevious from 'helpers/usePrevious';
import Loader from 'components/Loader/Loader';
import Swal from 'sweetalert2';
import Pagination from 'react-js-pagination';
import { getListMkKhs } from 'services/khs';
import { getDetailPersentaseNilai } from 'services/persentaseNilai';
import ModalPersentaseNilai from 'components/Modal/PersentaseNilai';
import { useHistory } from 'react-router-dom';

const Penilaian = (props) => {

  const [data, setData] = useState([]);
  const [dataPersentase, setDataPersentase] = useState({
    absensi: '',
    tugas: '',
    quiz: '',
    uts: '',
    uas: '',
    tugas_akhir: '',
    praktikum: ''
  });
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const debounceSearch = useDebounce(search, 500);

  const prevSearch = usePrevious(debounceSearch)

  const history = useHistory()

  useEffect(() => {
    getData()
  }, [debounceSearch])

  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListMkKhs(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const getPersentaseNilai = async (value, tahun_akademik) => {
    setLoading(true)
    const response = await getDetailPersentaseNilai(value, { tahun_akademik: tahun_akademik })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (response.results) {
        setDataPersentase(response.results)
        const split = props?.location?.pathname?.split('/')
        history.push(`/${split[1]}/penilaian-mahasiswa/daftar-mahasiswa`)
      } else {
        toggle()
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  return (
    <Row>
      <Col xl="12">
        <Card className="shadow">
          {loading && <Loader />}
          <ModalPersentaseNilai data={dataPersentase} toggle={toggle} setLoading={setLoading} isOpen={modal} />
          <CardHeader className="border-0">
            <Row className="mt-3">
              <Col md="8">
                <h3 className="mb-0">Daftar Mata Kuliah untuk Penilaian</h3>
              </Col>
              <Col md="4">
                <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Tahun Akademik</th>
                <th scope="col">Kode MK</th>
                <th scope="col">Mata Kuliah</th>
                <th scope="col">SKS</th>
                <th scope="col">Semester</th>
                <th scope="col">Prodi</th>
                <th scope="col">Jumlah Mahasiswa</th>
                <th scope="col">Status</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? data.map((val, idx) => {
                return (
                  <tr key={String(idx)}>
                    <th scope="row">
                      {val.tahun_akademik}
                    </th>
                    <th scope="row">
                      {val.kode_mk}
                    </th>
                    <th scope="row">
                      {val.mata_kuliah}
                    </th>
                    <th scope="row">
                      {val.total_sks}
                    </th>
                    <th scope="row">
                      {val.semester}
                    </th>
                    <th scope="row">
                      {val.prodi}
                    </th>
                    <th scope="row">
                      {val.mahasiswa}
                    </th>
                    <th>
                      {val.jumlah_penilaian === val.mahasiswa && val.mahasiswa > 0 ? 'Selesai' : 'Belum'}
                    </th>
                    <td>
                      <Button type="button" color="primary" size="sm" onClick={() => {
                        localStorage.setItem('id_matakuliah', val.id)
                        localStorage.setItem('tahun_akademik', val.tahun_akademik)
                        localStorage.setItem('mata_kuliah', val.mata_kuliah)
                        getPersentaseNilai(val.id, val.tahun_akademik)
                      }}>
                        Lanjut Penilaian
                      </Button>
                    </td>
                  </tr>
                )
              }) : (
                <tr>
                  <th colSpan="6" className="text-center">
                    Data Empty
                  </th>
                </tr>
              )}
            </tbody>
          </Table>
          <CardFooter className="py-4">
            {pageInfo.totalData > 0 && (
              <div className="d-flex justify-content-end mt-4">
                <Pagination
                  activePage={page}
                  itemsCountPerPage={15}
                  totalItemsCount={pageInfo.totalData}
                  pageRangeDisplayed={3}
                  onChange={handleChangePage.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            )}
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default Penilaian;
