/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateDosenWali, setUpdateDosenWali } from 'services/dosenWali';

const ModalDosenWali = ({ isOpen, toggle, created, getData, setLoading, data, listDosen, listMahasiswa }) => {

  const [dosen, setDosen] = useState('');
  const [mahasiswa, setMahasiswa] = useState('');

  useEffect(() => {
    if (!created && data) {
      if (data.mahasiswa) {
        const valueMhs = listMahasiswa.filter(val => val.label === data.mahasiswa)[0]
        setMahasiswa(valueMhs)
      } else {
        setMahasiswa('')
      }
      if (data.dosen) {
        const valueDosen = listDosen.filter(val => val.label === data.dosen)[0]
        setDosen(valueDosen)
      } else {
        setDosen('')
      }
    } else {
      setDosen('');
      setMahasiswa('');
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const payload = {
      id_mahasiswa: mahasiswa?.value,
      id_dosen: dosen?.value,
    }

    if (!dosen || !mahasiswa) {
      Swal.fire({
        icon: 'error',
        title: 'Isian Form harus diisi semua!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateDosenWali(payload)
      } else {
        response = await setUpdateDosenWali(payload, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Dosen Wali</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Mahasiswa</Label>
                <Select
                  name="mahasiswa"
                  placeholder="Select Mahasiswa"
                  options={listMahasiswa}
                  defaultValue={mahasiswa}
                  onChange={(e) => setMahasiswa(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Dosen</Label>
                <Select
                  name="dosen"
                  placeholder="Select Dosen"
                  options={listDosen}
                  defaultValue={dosen}
                  onChange={(e) => setDosen(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalDosenWali;
