/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, CardFooter, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap';
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import { getListMhs, getDetailMhs } from 'services/mahasiswa';
import ModalMahasiswa from 'components/Modal/Mahasiswa';
import Header from "components/Headers/Header";

const Mahasiswa = () => {

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    totalData: 0,
    totalPage: 0
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState(false);
  const [dataModal, setDataModal] = useState('');
  const debounceSearch = useDebounce(search, 500);

  const toggle = () => setIsOpen(!isOpen);

  const prevSearch = usePrevious(debounceSearch)

  useEffect(() => {
    getData()
  }, [debounceSearch])


  const getData = async (value, refresh) => {
    setLoading(true)
    let newPage
    if (prevSearch !== debounceSearch || refresh) {
      newPage = 1
    }
    const params = {
      page: value ? value : newPage,
      search
    }
    const response = await getListMhs(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results.data)
      const pageInfo = {
        totalData: response.results.count,
        totalPage: response.results.pageCount
      }
      setPageInfo(pageInfo)
      if (prevSearch !== debounceSearch || refresh) {
        setPage(newPage)
      }
    }
    setLoading(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    getData(page)
  }

  const getMahasiswa = async (email) => {
    setLoading(true)
    const response = await getDetailMhs({ email })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setDataModal(response.results)
    }
    setLoading(false)
  }

  return (
    <>
      {loading && <Loader />}
      <ModalMahasiswa
        toggle={() => toggle()}
        setLoading={setLoading}
        isOpen={isOpen}
        view={view}
        data={dataModal}
      />
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Daftar Mahasiswa</h3>
                <Row className="mt-3">
                  <Col md="4">
                    <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">NIM</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Email</th>
                    <th scope="col">Prodi</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? data.map((val, idx) => {
                    return (
                      <tr key={String(idx)}>
                        <th scope="row">
                          {((page - 1) * 15) + (idx + 1)}
                        </th>
                        <th scope="row">
                          {val.nim}
                        </th>
                        <th scope="row">
                          {val.name}
                        </th>
                        <th scope="row">
                          {val.email}
                        </th>
                        <th scope="row">
                          {val.Prodi.name}
                        </th>
                        <td>
                          <Button type="button" size="sm" onClick={async () => {
                            setView(true);
                            await getMahasiswa(val.email)
                            toggle();
                          }}>
                            <i className="fa fa-eye" />
                          </Button>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <th colSpan="6" className="text-center">
                        Data Empty
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {pageInfo.totalData > 0 && (
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={15}
                      totalItemsCount={pageInfo.totalData}
                      pageRangeDisplayed={3}
                      onChange={handleChangePage.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Mahasiswa