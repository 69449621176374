import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { setKeputusanKrsMhs } from 'services/krs';
import Swal from 'sweetalert2';
const ModalPerwalian = ({ isOpen, toggle, data, getData, setLoading }) => {

  const [krs, setKrs] = useState([]);

  useEffect(() => {
    setKrs([])
  }, [isOpen]);

  const submit = async () => {
    setLoading(true)
      const response = await setKeputusanKrsMhs(krs)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>Keputusan Perwalian ({data.mahasiswa})</ModalHeader>
      <ModalBody className="pt-1">
        <div className="table-wrap">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Kode MK</th>
                <th scope="col">Mata Kuliah</th>
                <th scope="col">SKS</th>
                <th scope="col">Semester</th>
                <th scope="col">Jenis Matkul</th>
                <th scope="col">Hari, Jam</th>
                <th scope="col">Kelas</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {data && data.data.length > 0 && (
                data.data.map((val, idx) => {
                  return (
                    <tr key={String(idx)}>
                      <th scope="row">
                        {val.kode_mk}
                      </th>
                      <th scope="row">
                        {val.mata_kuliah}
                      </th>
                      <th scope="row">
                        {val.total_sks}
                      </th>
                      <th scope="row">
                        {val.semester}
                      </th>
                      <th scope="row">
                        {val.jenis_matkul}
                      </th>
                      <th scope="row">
                        {val.hari}, {val.jam}
                      </th>
                      <th scope="row">
                        {val.kelas}
                      </th>
                      <th scope="row">
                        <div>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="radio" checked={krs.findIndex(value => value.status === 'diterima' && value.id_matakuliah === val.id) !== -1} onChange={(e) => {
                              const index = krs.findIndex((value) => value.id_matakuliah === val.id)
                              if (index !== -1) {
                                let result = [...krs]
                                result[index].id_matakuliah = val.id
                                result[index].status = 'diterima'
                                result[index].id_mahasiswa = data.id_mahasiswa
                                setKrs(result)
                              } else {
                                let value = [...krs, { id_matakuliah: val.id, status: 'diterima', id_mahasiswa: data.id_mahasiswa }]
                                setKrs(value)
                              }
                            }} />
                            <Label check>
                              Diterima
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="radio" checked={krs.findIndex(value => value.status === 'ditolak' && value.id_matakuliah === val.id) !== -1 } onChange={(e) => {
                               const index = krs.findIndex((value) => value.id_matakuliah === val.id)
                               if (index !== -1) {
                                 let result = [...krs]
                                 result[index].id_matakuliah = val.id
                                 result[index].status = 'ditolak'
                                 result[index].id_mahasiswa = data.id_mahasiswa
                                 setKrs(result)
                               } else {
                                 let value = [...krs, { id_matakuliah: val.id, status: 'ditolak', id_mahasiswa: data.id_mahasiswa }]
                                 setKrs(value)
                               }
                            }} />
                            <Label check>
                              Ditolak
                            </Label>
                          </FormGroup>
                        </div>
                      </th>
                    </tr>
                  )
                })
              )}
            </tbody>
          </Table>
        </div>
        <Button color="primary" type="button" onClick={() => submit()} disabled={data?.data?.length !== krs.length}>Kirim Keputusan</Button>
      </ModalBody>
    </Modal>
  );
}

export default ModalPerwalian;
