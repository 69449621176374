import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}prodi`

export const getListProdi = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params
  })
}

export const getListKaprodi = async () => {
  const urlKaprodi = `${ROOT_API}dosen-staff/kaprodi`
  return callAPI({
    url: urlKaprodi,
    method: 'GET',
    token: true
  })
}

export const setCreateProdi = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateProdi = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteProdi = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}

export const getCountSementer = async () => {

  return callAPI({
    url: `${url}/semester`,
    method: 'GET',
    token: true
  })
}