export const optionPenghasilan = [
  {
    value: "1",
    label: "Tidak Berpenghasilan"
  },
  {
    value: "2",
    label: "< Rp 500.000"
  },
  {
    value: "3",
    label: "Rp 500.000 - Rp 999.999"
  },
  {
    value: "4",
    label: "Rp 1.000.000 - Rp 1.999.999"
  },
  {
    value: "5",
    label: "Rp 2.000.000 - Rp 4.999.999"
  },
  {
    value: "6",
    label: "Rp 5.000.000 - Rp 20.000.000"
  },
  {
    value: "7",
    label: "> Rp 20.000.000"
  }
]

export const optionPekerjaan = [
  {
    value: "1",
    label: "Tidak bekerja"
  },
  {
    value: "2",
    label: "Nelayan"
  },
  {
    value: "3",
    label: "Petani"
  },
  {
    value: "4",
    label: "Peternak"
  },
  {
    value: "5",
    label: "PNS/TNI/Polri"
  },
  {
    value: "6",
    label: "Karyawan Swasta"
  },
  {
    value: "7",
    label: "Pedagang Kecil"
  },
  {
    value: "8",
    label: "Pedagang Besar"
  },
  {
    value: "9",
    label: "Wiraswasta"
  },
  {
    value: "10",
    label: "Wirausaha"
  },
  {
    value: "11",
    label: "Buruh"
  },
  {
    value: "12",
    label: "Pensiunan"
  },
  {
    value: "13",
    label: "Sudah Meninggal"
  },
  {
    value: "14",
    label: "Lainnya"
  }
]

export const optionPendidikan = [
  {
    value: "1",
    label: "Tidak sekolah"
  },
  {
    value: "2",
    label: "PAUD"
  },
  {
    value: "3",
    label: "TK / sederajat"
  },
  {
    value: "4",
    label: "Putus SD"
  },
  {
    value: "5",
    label: "SD / sederajat"
  },
  {
    value: "6",
    label: "SMP / sederajat"
  },
  {
    value: "7",
    label: "SMA / sederajat"
  },
  {
    value: "8",
    label: "Paket A"
  },
  {
    value: "9",
    label: "Paket B"
  },
  {
    value: "10",
    label: "Paket C"
  },
  {
    value: "11",
    label: "D1"
  },
  {
    value: "12",
    label: "D2"
  },
  {
    value: "13",
    label: "D3"
  },
  {
    value: "14",
    label: "D4"
  },
  {
    value: "15",
    label: "S1"
  },
  {
    value: "16",
    label: "Profesi"
  },
  {
    value: "17",
    label: "Sp-1"
  },
  {
    value: "18",
    label: "S2"
  },
  {
    value: "19",
    label: "S2 Terapan"
  },
  {
    value: "20",
    label: "Sp-2"
  },
  {
    value: "21",
    label: "S3"
  },
  {
    value: "22",
    label: "S3 Terapan"
  },
  {
    value: "23",
    label: "Non formal"
  },
  {
    value: "24",
    label: "Informal"
  },
  {
    value: "25",
    label: "Lainnya"
  }
]

export const optionAgama = [
  {
    label: "Islam",
    value: "islam"
  },
  {
    label: "Kristen",
    value: "kristen"
  },
  {
    label: "Katolik",
    value: "katolik"
  },
  {
    label: "Hindu",
    value: "hindu"
  },
  {
    label: "Budha",
    value: "budha"
  },
  {
    label: "Lainnya",
    value: "lainnya"
  }
]

export const optionJenjang = [
  {
    label: "D3",
    value: "D3"
  },
  {
    label: "D4",
    value: "D4"
  },
  {
    value: "Profesi",
    label: "Profesi"
  },
  {
    label: "S1",
    value: "S1"
  },
  {
    label: "S1 Terapan",
    value: "S1 Terapan"
  },
  {
    label: "S2",
    value: "S2"
  },
  {
    label: "S2 Terapan",
    value: "S2 Terapan"
  },
  {
    label: "S3",
    value: "S3"
  },
  {
    label: "S3 Terapan",
    value: "S3 Terapan"
  }
]

export const optionAkreditas = [
  {
    label: "Baik Sekali",
    value: "A"
  },
  {
    label: "Baik",
    value: "B"
  },
  {
    label: "Unggul",
    value: "C"
  },
]

export const optionJenisMatkul = [
  {
    label: "WAJIB NASIONAL",
    value: "WAJIB NASIONAL"
  },
  {
    label: "WAJIB PRODI",
    value: "WAJIB PRODI"
  },
  {
    label: "PILIHAN",
    value: "PILIHAN"
  },
  {
    label: "PERMINTAAN",
    value: "PERMINTAAN"
  },
  {
    label: "TUGAS AKHIR/SKRIPSI/TESIS/DISERTASI",
    value: "TUGAS AKHIR"
  }
]

export const optionJenisPembelajaran = [
  {
    label: "Wajib",
    value: "wajib"
  },
  {
    label: "Tidak Wajib",
    value: "tidakwajib"
  },
  {
    label: "Pilihan",
    value: "pilihan"
  }
]

export const optionMonth = [
  {
    label: 'Januari',
    value: 1,
  },
  {
    label: 'Februari',
    value: 2,
  },
  {
    label: 'Maret',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'Mei',
    value: 5,
  },
  {
    label: 'Juni',
    value: 6,
  },
  {
    label: 'Juli',
    value: 7,
  },
  {
    label: 'Agustus',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'Oktober',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'Desember',
    value: 12,
  },
]