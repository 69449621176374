/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateJadwalKuliah } from 'services/jadwalkuliah';
import { setUpdateJadwalKuliah } from 'services/jadwalkuliah';
import { getListMatkul } from 'services/matakuliah';

const maxYear = new Date().getFullYear();
const minYear = 2022
const yearArray = []

let i = 0;
let j = 0;

do {
    yearArray.push({
      label: `${String(maxYear-i)}/${String(maxYear - i + 1)} ${j % 2 === 0 ? 'Genap' : 'Ganjil'}`,
      value: `${String(maxYear-i)}/${String(maxYear - i + 1)} ${j % 2 === 0 ? 'Genap' : 'Ganjil'}`,
    });
    if (yearArray.length % 2 === 0) {
      i++;
    }
    j++
} while (maxYear-i >= minYear)

const ModalJadwalKuliah = ({ isOpen, toggle, created, getData, setLoading, data, listKelas, listDosen, listProdi }) => {

  const [mataKuliah, setMatakuliah] = useState('');
  const [prodi, setProdi] = useState('');
  const [dosen, setDosen] = useState('');
  const [kelas, setKelas] = useState('');
  const [hari, setHari] = useState('');
  const [jamMulai, setJamMulai] = useState('');
  const [jamSelesai, setJamSelesai] = useState('');
  const [optionMatkul, setOptionMatkul] = useState([]);
  const [tahunAkademik, setTahunAkdemik] = useState('');

  const listHari = [
    {
      label: 'Senin',
      value: 'Senin'
    },
    {
      label: 'Selasa',
      value: 'Selasa'
    },
    {
      label: 'Rabu',
      value: 'Rabu'
    },
    {
      label: 'Kamis',
      value: 'Kamis'
    },
    {
      label: 'Jumat',
      value: 'Jumat'
    },
    {
      label: 'Sabtu',
      value: 'Sabtu'
    },
    {
      label: 'Minggu',
      value: 'Minggu'
    },
  ]

  useEffect(() => {
    if (!created && data) {
      if (data.id_mata_kuliah) {
        getDataMatkul(data.id_prodi, data.id_mata_kuliah)
      } else {
        setMatakuliah('')
      }
      if (data.id_prodi) {
        const valueProdi = listProdi.filter(val => val.value === data.id_prodi)[0]
        setProdi(valueProdi)
      } else {
        setProdi('')
      }
      if (data.id_dosen) {
        const valueDosen = listDosen.filter(val => val.value === data.id_dosen)[0]
        setDosen(valueDosen)
      } else {
        setDosen('')
      }
      if (data.id_kelas) {
        const valueKelas = listKelas.filter(val => val.value === data.id_kelas)[0]
        setKelas(valueKelas)
      } else {
        setKelas('')
      }
      if (data.tahun_akademik) {
        const valueTahunAkademik = yearArray.filter(val => val.value === data.tahun_akademik)[0]
        setTahunAkdemik(valueTahunAkademik)
      } else {
        setTahunAkdemik('')
      }
      if (data.jam) {
        const value = data.jam.split('-')
        setJamMulai(value[0])
        setJamSelesai(value[1])
      } else {
        setJamMulai('');
        setJamSelesai('');
      }
      if (data.hari) {
        const value = listHari.filter(val => val.value === data.hari)[0]
        setHari(value)
      } else {
        setHari('')
      }
    } else {
      setMatakuliah('');
      setDosen('');
      setKelas('');
      setHari('');
      setJamMulai('');
      setJamSelesai('');
      setProdi('');
      setTahunAkdemik('');
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const payload = {
      id_mata_kuliah: mataKuliah?.value,
      id_prodi: prodi?.value,
      id_dosen: dosen?.value,
      id_kelas: kelas?.value,
      hari: hari?.value,
      jam: `${jamMulai}-${jamSelesai}`,
      tahun_akademik: tahunAkademik?.value
    }

    if (!mataKuliah || !dosen || !kelas || !hari || !jamMulai || !jamSelesai || !tahunAkademik) {
      Swal.fire({
        icon: 'error',
        title: 'Isian Form harus diisi semua!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateJadwalKuliah(payload)
      } else {
        response = await setUpdateJadwalKuliah(payload, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  useEffect(() => {
    if (prodi) {
      getDataMatkul(prodi?.value);
    }
  }, [prodi]);

  const getDataMatkul = async (id_prodi, id_matkul) => {
    const response = await getListMatkul({ matkul: true, prodi: id_prodi })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataMatkul = response.results.map(val => {
        return {
          label: val.mata_kuliah,
          value: val.id
        }
      })
      setOptionMatkul(dataMatkul)
      if (id_matkul) {
        const valueMatkul = dataMatkul.filter(val => val.value === id_matkul)[0]
        setMatakuliah(valueMatkul)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Jadwal Kuliah</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Dosen</Label>
                <Select
                  name="dosen"
                  placeholder="Select Dosen"
                  options={listDosen}
                  defaultValue={dosen}
                  onChange={(e) => setDosen(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Prodi</Label>
                <Select
                  name="matkul"
                  placeholder="Select Prodi"
                  options={listProdi}
                  defaultValue={prodi}
                  onChange={(e) => {
                    setProdi(e)
                    setMatakuliah('');
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Mata Kuliah</Label>
                <Select
                  name="matkul"
                  placeholder="Select Mata Kuliah"
                  options={optionMatkul}
                  value={mataKuliah}
                  onChange={(e) => setMatakuliah(e)}
                  isDisabled={!prodi}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Kelas</Label>
                <Select
                  name="kelas"
                  placeholder="Select Kelas"
                  options={listKelas}
                  defaultValue={kelas}
                  onChange={(e) => setKelas(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Hari</Label>
                <Select
                  name="hari"
                  placeholder="Select Hari"
                  options={listHari}
                  defaultValue={hari}
                  onChange={(e) => setHari(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jam Mulai</Label>
                <Input type='time' value={jamMulai} onChange={(e) => setJamMulai(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jam Selesai</Label>
                <Input type='time' value={jamSelesai} onChange={(e) => setJamSelesai(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Tahun Akademik</Label>
                <Select
                  name="tahun-akademik"
                  placeholder="Select Tahun Akademik"
                  options={yearArray}
                  defaultValue={tahunAkademik}
                  onChange={(e) => setTahunAkdemik(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalJadwalKuliah;
