import React from "react";
import { Route, Switch } from "react-router-dom";
import ProfileMahasiswa from "views/mahasiswa/Profile";
import SuntingProfile from "views/mahasiswa/SuntingProfile";
import Header from "components/Headers/Header";
import { Redirect } from "react-router-dom";
import ChangePassword from "views/mahasiswa/ChangePassword";

const ProfileMhs = (props) => {

  const routes = [
    {
      path: "/user-profile/user",
      component: ProfileMahasiswa,
      layout: "/mahasiswa"
    },
    {
      path: "/user-profile/sunting",
      component: SuntingProfile,
      layout: "/mahasiswa"
    },
    {
      path: "/user-profile/change-password",
      component: ChangePassword,
      layout: "/mahasiswa"
    }
  ]

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/mahasiswa") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Header />
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/mahasiswa/user-profile/user" />
      </Switch>
    </>
  );
};

export default ProfileMhs;
