import moment from 'moment'
import React, { forwardRef } from 'react'
import { Col, Row, Table } from 'reactstrap'

const PDFKhs = forwardRef((props, ref) => {
    const bobot = {
        'A': 4.00,
        'A-': 3.75,
        'B+': 3.5,
        'B': 3.00,
        'B-': 2.75,
        'C+': 2.5,
        'C': 2.00,
        'C-': 1.75,
        'D': 1,
        'E': 0.00
    }

    const total_sks = props.data.length > 0 ? props.data.reduce(function (acc, obj) { return acc + obj.Matakuliah.total_sks; }, 0) : 0
    const nilaiSks = props.data.length > 0 ? props.data.reduce(function (acc, obj) { return acc + (obj.Matakuliah.total_sks * (bobot[obj.grade] || 0)); }, 0) : 0

    return (
        <div className='d-none'>
            <div ref={ref} className="px-5 py-3">
                <div className='d-flex mb-2 w-100'>
                    <div>
                        <img
                            alt="..."
                            src={
                                require("../../assets/img/brand/logo_kmc.png").default
                            }
                            width={60}
                            height={60}
                        />
                    </div>
                    <div className="ml-5">
                        <h1>
                            POLITEKNIK KESEHATAN KMC KUNINGAN
                        </h1>
                        <p className='mb-0'>
                            SK KEMENDIKBUDRISTEK RI Nomor: 248/D/OT/OT/2021 Tanggal 30 Desember 2021
                        </p>
                        <p className='mb-0'>
                            Alamat Jl. Pramuka No. 65 Kelurahan Purwawinangun
                        </p>
                        <p className='mb-1'>
                            Kecamatan Kuningan, Kabupaten Kuningan
                        </p>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/whatsapp.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>081324699698</p>
                            </div>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/email.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>poltekeskmc@gmail.com</p>
                            </div>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/facebook.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>Poltekes KMC Kuningan</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='mb-3'>
                    <h4 className='text-center'>
                        KARTU HASIL STUDI (KHS)
                    </h4>
                    {/* <h4 className='text-center'>
                        SEMESTER {Number(props?.semester) % 2 === 0 ? 'Genap' : 'Ganjil'} {new Date().getFullYear() - 1}/{new Date().getFullYear()}
                    </h4> */}
                    <h4 className='text-center'>
                        SEMESTER {Number(props?.semester) % 2 === 0 ? 'Genap' : 'Ganjil'}
                    </h4>
                </div>
                <Row className='mb-2'>
                    <Col lg={6} md={6} xs={6} sm={6}>
                        <Row>
                            <Col lg={3} md={3} xs={3} sm={3}>
                                <h6>NIM</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.nim}</h6>
                            </Col>
                            <Col g={3} md={3} xs={3} sm={3}>
                                <h6>Nama</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.name}</h6>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} xs={6} sm={6}>
                        <Row>
                            <Col g={3} md={3} xs={3} sm={3}>
                                <h6>Program Studi</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.prodi}</h6>
                            </Col>
                            <Col g={3} md={3} xs={3} sm={3}>
                                <h6>Semester</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.semester}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Table className="align-items-center" bordered>
                    <thead>
                        <tr>
                            <th scope="col" rowSpan={2} className='py-1'>No</th>
                            <th scope="col" rowSpan={2} className='py-1'>Kode MK</th>
                            <th scope="col" rowSpan={2} className='py-1'>Mata Kuliah</th>
                            <th scope="col" rowSpan={2} className='py-1'>SKS</th>
                            <th scope="col" colSpan={2} className='text-center py-1'>Nilai (N)</th>
                            <th scope="col" rowSpan={2} className='py-1'>SKS x N</th>
                            <th scope="col" rowSpan={2} className='py-1'>Keterangan</th>
                        </tr>
                        <tr>
                            <th scope='col'>Huruf</th>
                            <th scope='col'>Bobot</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.length > 0 ? props.data.map((val, idx) => {
                            return (
                                <tr key={String(idx)}>
                                    <th className='py-1'>
                                        {idx + 1}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {val.Matakuliah.kode_mk}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {val.Matakuliah.mata_kuliah}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {val.Matakuliah.total_sks}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {val.grade}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {bobot[val.grade]}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {val.Matakuliah.total_sks * (bobot[val.grade] || 0)}
                                    </th>
                                    <th scope="row" className='py-1'>
                                        {(val.grade === 'A' || val.grade === 'A-' || val.grade === 'B+' || val.grade === 'B' || val.grade === 'B-' || val.grade === 'C+' || val.grade === 'C' || val.grade === 'C-') ? 'Lulus' : 'Tidak Lulus'}
                                    </th>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <th colSpan="9" className="text-center">
                                    Data Empty
                                </th>
                            </tr>
                        )}
                        <tr>
                            <th colSpan={3}>Jumlah SKS</th>
                            <th scope="row">
                                {total_sks}
                            </th>
                            <th colSpan={2} />
                            <th scope="row">
                                {nilaiSks}
                            </th>
                            <th />
                        </tr>
                    </tbody>
                </Table>
                <div className='mb-3'>
                    <h6>Indeks Prestasi Semester: {(nilaiSks / total_sks).toFixed(2)}</h6>
                    <h6>Indeks Prestasi Kumulatif: {props.ipk}</h6>
                    <h6>Jumlah SKS yang ditempuh: {props.sks}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className='ttd-krs'>
                        <p className='mb-0 text-center'>Kuningan, {moment().format('DD MMMM YYYY')}</p>
                        <p className='text-center mb-5'>Wakil Direktur 1</p>
                        <p className='text-center mb-5'>{props?.wadir}</p>
                    </div>
                    <div className='ttd-krs'>
                        <p className='mb-0 text-center'>Kuningan, {moment().format('DD MMMM YYYY')}</p>
                        <p className='text-center mb-5'>Kepala Bagian Akademik</p>
                        <p className='text-center mb-5'>{props?.akademik}</p>
                    </div>
                    <div className='ttd-krs'>
                        <p className='mb-0 text-center'>Kuningan, {moment().format('DD MMMM YYYY')}</p>
                        <p className='text-center mb-0'>Ketua Program Prodi</p>
                        <p className='text-center mb-5'>{props?.kaprodi?.prodi}</p>
                        <p className='text-center mb-5'>{props?.kaprodi?.name}</p>
                    </div>
                </div>
                <div className='pagebreak pt-3'>
                    <h6 className='mb-3'>Detail Nilai</h6>
                    <Row>
                        {props.data.length > 0 && props.data.map((val, idx) => {
                            return (
                                <Col lg={4} md={4} sm={4} xs={4} key={String(idx)}>
                                    <div className='d-flex'>
                                        <h6 className='mr-2'>{idx + 1}.</h6>
                                        <div>
                                            <h6>{val.Matakuliah.mata_kuliah}</h6>
                                            <h6 hidden={!val.absensi}>Absensi: {val.absensi}</h6>
                                            <h6 hidden={!val.tugas}>Tugas: {val.absensi}</h6>
                                            <h6 hidden={!val.quiz}>Quiz: {val.quiz}</h6>
                                            <h6 hidden={!val.uts}>UTS: {val.uts}</h6>
                                            <h6 hidden={!val.uas}>UAS: {val.uas}</h6>
                                            <h6 hidden={!val.praktikum}>Praktikum: {val.absensi}</h6>
                                            <h6>Nilai Akhir: {val.total_nilais}</h6>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        </div>
    )
})

export default PDFKhs