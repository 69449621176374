/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select'
import { getListProvinsi, getListKabupaten, getListKecamatan, getListKelurahan } from 'services/daerahIndonesia';
import Swal from 'sweetalert2';
import { optionAgama } from 'helpers/optionData';
import ModalPreview from './Preview';
import { setRegisterDosenStaff, setUpdateProfileDosenStaff } from 'services/dosenStaff';
import { getListProdi } from "services/prodi";
import moment from 'moment';
import { getListRole } from 'services/role';

const ModalDosenStaff = ({ isOpen, toggle, created, getData, setLoading, data }) => {
  const [dosenStaff, setDosenStaff] = useState({
    name: '',
    email: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    agama: '',
    nik: '',
    alamat: '',
    provinsi: '',
    kabupaten: '',
    kecamatan: '',
    kelurahan: '',
    kewarganegaraan: 'Indonesia',
    kode_pos: '',
    telepon: '',
    nip: '',
    nidn: ''
  });
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [optionProvinsi, setOptionProvinsi] = useState([]);
  const [optionKabupaten, setOptionKabupaten] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionKelurahan, setOptionKelurahan] = useState([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [optionProdi, setOptionProdi] = useState([]);
  const [prodi, setProdi] = useState('');
  const [optionRole, setOptionRole] = useState([]);
  const [role, setRole] = useState('');

  const getDataProvinsi = async (provinsi) => {
    const response = await getListProvinsi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProvinsi = response.provinsi.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionProvinsi(dataProvinsi)
      if (provinsi) {
        const resultProvinsi = dataProvinsi.filter(val => val.value === Number(provinsi))[0]
        return resultProvinsi
      }
    }
  }

  const getDataKabupaten = async (provinsi, kabupaten) => {
    const response = await getListKabupaten(provinsi)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKabupaten = response.kota_kabupaten.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKabupaten(dataKabupaten)
      if (kabupaten) {
        const resultKabupaten = dataKabupaten.filter(val => val.value === Number(kabupaten))[0]
        return resultKabupaten
      }
    }
  }

  const getDataKecamatan = async (kabupaten, kecamatan) => {
    const response = await getListKecamatan(kabupaten)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKecamatan = response.kecamatan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKecamatan(dataKecamatan)
      if (kecamatan) {
        const resultKecamatan = dataKecamatan.filter(val => val.value === Number(kecamatan))[0]
        return resultKecamatan
      }
    }
  }

  const getDataKelurahan = async (kecamatan, kelurahan) => {
    const response = await getListKelurahan(kecamatan)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKelurahan = response.kelurahan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKelurahan(dataKelurahan)
      if (kelurahan) {
        const resultKelurahan = dataKelurahan.filter(val => val.value === Number(kelurahan))[0]
        return resultKelurahan
      }
    }
  }

  const getDataProdi = async (value) => {
    const response = await getListProdi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProdi = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionProdi(dataProdi)
      if (value) {
        const valueProdi = dataProdi.filter(val => val.value === value)[0]
        setProdi(valueProdi)
      }
    }
  }

  const getDataRole = async (value) => {
    const response = await getListRole()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataRole = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      }).filter(val => val.label !== 'Mahasiswa')
      setOptionRole(dataRole)
      if (value) {
        const valueRole = dataRole.filter(val => val.value === value)[0]
        setRole(valueRole)
      }
    }
  }

  const initialData = async () => {
    let provinsi = ''
    let kabupaten = ''
    let kecamatan = ''
    let kelurahan = ''
    if (data.provinsi) {
      provinsi = await getDataProvinsi(data.provinsi)
    }
    if (data.kabupaten) {
      kabupaten = await getDataKabupaten(data.provinsi, data.kabupaten)
    }
    if (data.kecamatan) {
      kecamatan = await getDataKecamatan(data.kabupaten, data.kecamatan)
    }
    if (data.kelurahan) {
      kelurahan = await getDataKelurahan(data.kecamatan, data.kelurahan)
    }
    if (data.id_prodi) {
      await getDataProdi(data.id_prodi)
    }
    if (data.id_role) {
      await getDataRole(data.id_role)
    }
    setDosenStaff({
      ...dosenStaff,
      name: data.name || '',
      email: data.email || '',
      tempat_lahir: data.tempat_lahir || '',
      tanggal_lahir: data.tanggal_lahir ? moment(data.tanggal_lahir).format('YYYY-MM-DD') : '',
      jenis_kelamin: data.jenis_kelamin || '',
      agama: data.agama ? optionAgama.filter(val => val.value === data.agama)[0] : '',
      nik: data.nik || '',
      nip: data.nip || '',
      nidn: data.nidn || '',
      telepon: data.telepon || '',
      alamat: data.alamat || '',
      provinsi,
      kabupaten,
      kecamatan,
      kelurahan,
      kewarganegaraan: 'Indonesia',
      kode_pos: data.kode_pos || '',
      photo: data.photo || ''
    })
  }

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', dosenStaff.name)
    formData.append('email', dosenStaff.email)
    formData.append('tempat_lahir', dosenStaff.tempat_lahir)
    formData.append('tanggal_lahir', dosenStaff.tanggal_lahir)
    formData.append('jenis_kelamin', dosenStaff.jenis_kelamin)
    formData.append('agama', dosenStaff.agama?.value)
    formData.append('nik', dosenStaff.nik)
    formData.append('telepon', dosenStaff.telepon)
    formData.append('alamat', dosenStaff.alamat)
    formData.append('kelurahan', dosenStaff.kelurahan?.value)
    formData.append('kecamatan', dosenStaff.kecamatan?.value)
    formData.append('kabupaten', dosenStaff.kabupaten?.value)
    formData.append('provinsi', dosenStaff.provinsi?.value)
    formData.append('kode_pos', dosenStaff.kode_pos)
    formData.append('nip', dosenStaff.nip)
    formData.append('nidn', dosenStaff.nidn)
    formData.append('status', true)
    if (prodi && (role?.label !== 'Akademik' || role?.label !== 'Administrator' || role?.label === 'Kepala Bagian Akademik' || role?.label === 'Wakil Direktur 1')) {
      formData.append('id_prodi', prodi?.value)
    }
    formData.append('id_role', role?.value)
    if (!data) {
      formData.append('password', 'kmckuningan')
    }

    if (data.id) {
      formData.append('id', data.id)
    }

    let errorValidasi
    delete dosenStaff.photo
    delete dosenStaff.nip
    delete dosenStaff.nidn
    Object.keys(dosenStaff).some(keys => {
      if (dosenStaff[keys] === '') {
        if (keys.search('_') >= 0) {
          errorValidasi = `${keys.split('_').join(' ')} wajib diisi!!!`
        } else {
          errorValidasi = `${keys.charAt(0).toUpperCase() + keys.slice(1)} wajib diisi!!!`
        }
      }
      return dosenStaff[keys] === '' || dosenStaff[keys] === null
    })

    if (errorValidasi) {
      Swal.fire({
        icon: 'error',
        title: errorValidasi,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (data) {
        response = await setUpdateProfileDosenStaff(formData)
      } else {
        response = await setRegisterDosenStaff(formData)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: `Dosen / Staff Berhasil ${data ? 'diupdate' : 'dibuat'}!!!`
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  useEffect(() => {
    if (!created && data) {
      // setLevel(data.name)
    } else {
      setDosenStaff({
        name: '',
        email: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        agama: '',
        nik: '',
        alamat: '',
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        kewarganegaraan: 'Indonesia',
        kode_pos: '',
        telepon: '',
        photo: ''
      })
      setPreviewPhoto('');
      setProdi('');
      setRole('');
    }
    initialData();
  }, [isOpen])

  useEffect(() => {
    getDataProvinsi()
    getDataProdi()
    getDataRole()
  }, [])

  return (
    <>
      <ModalPreview
        isOpen={isOpenPreview}
        toggle={() => setIsOpenPreview(!isOpenPreview)}
        data={previewPhoto ? previewPhoto : `${process.env.REACT_APP_IMAGE_URL}/images/photo/${dosenStaff.photo}`}
      />
      <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Dosen / Staff</ModalHeader>
        <ModalBody className="pt-1">
          <Form onSubmit={submit}>
            <Row>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Nama</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={dosenStaff.name}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, name: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Email</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="email"
                        value={dosenStaff.email}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, email: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Tempat Lahir</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={dosenStaff.tempat_lahir}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, tempat_lahir: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Tanggal Lahir</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="date"
                        value={dosenStaff.tanggal_lahir}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, tanggal_lahir: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Jenis Kelamin</Label>
                      <div className="py-2 pb-3">
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={dosenStaff.jenis_kelamin === "Laki-laki"}
                            value="Laki-laki"
                            onChange={(e) => setDosenStaff({ ...dosenStaff, jenis_kelamin: e.target.value })}
                          />
                          <Label check>
                            Laki-laki
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            type="radio"
                            checked={dosenStaff.jenis_kelamin === "Perempuan"}
                            value="Perempuan"
                            onChange={(e) => setDosenStaff({ ...dosenStaff, jenis_kelamin: e.target.value })}
                          />
                          <Label check>
                            Perempuan
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Agama
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="agama"
                        placeholder="Select Agama"
                        value={dosenStaff.agama}
                        options={optionAgama}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, agama: e })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>NIK</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={dosenStaff.nik}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, nik: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Telepon</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={dosenStaff.telepon}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, telepon: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Kode Pos</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="number"
                        value={dosenStaff.kode_pos}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, kode_pos: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Alamat</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={dosenStaff.alamat}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, alamat: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label
                      className='required'>
                        Role
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="program-studi"
                        placeholder="Select Role"
                        options={optionRole}
                        value={role}
                        onChange={(e) => setRole(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" hidden={role?.label === 'Akademik' || role?.label === 'Administrator' || role?.label === 'Kepala Bagian Akademik' || role?.label === 'Wakil Direktur 1'}>
                    <FormGroup className="form-custom">
                      <Label
                      className='required'>
                        Program Studi
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="program-studi"
                        placeholder="Select Program Studi"
                        options={optionProdi}
                        value={prodi}
                        onChange={(e) => setProdi(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>NIP</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={dosenStaff.nip}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, nip: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label>NIDN</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        type="text"
                        value={dosenStaff.nidn}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, nidn: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>Provinsi</Label>
                      <Select
                        className="form-control-alternative"
                        name="provinsi"
                        placeholder="Select Provinsi"
                        value={dosenStaff.provinsi}
                        options={optionProvinsi}
                        onChange={(e) => {
                          setDosenStaff({ ...dosenStaff, provinsi: e, kabupaten: '', kecamatan: '', kelurahan: '' })
                          getDataKabupaten(e.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Kabupaten / Kota
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="kabupaten"
                        placeholder="Select Kabupaten / Kota"
                        value={dosenStaff.kabupaten}
                        isDisabled={!dosenStaff.provinsi}
                        options={optionKabupaten}
                        onChange={(e) => {
                          setDosenStaff({ ...dosenStaff, kabupaten: e, kecamatan: '' })
                          getDataKecamatan(e.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>
                        Kecamatan
                      </Label>
                      <Select
                        className="form-control-alternative"
                        name="kecamatan"
                        placeholder="Select Kecamatan"
                        value={dosenStaff.kecamatan}
                        isDisabled={!dosenStaff.kabupaten}
                        options={optionKecamatan}
                        onChange={(e) => {
                          setDosenStaff({ ...dosenStaff, kecamatan: e, kelurahan: '' })
                          getDataKelurahan(e.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="form-custom">
                      <Label className='required'>Kelurahan / Desa</Label>
                      <Select
                        className="form-control-alternative"
                        name="kelurahan"
                        placeholder="Select Kelurahan"
                        value={dosenStaff.kelurahan}
                        isDisabled={!dosenStaff.kecamatan}
                        options={optionKelurahan}
                        onChange={(e) => setDosenStaff({ ...dosenStaff, kelurahan: e })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label className='required'>Kewarganegaraan</Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Drop some text here..."
                        value="Indonesia"
                        disabled
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button color="primary" type="submit">Simpan Perubahan</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalDosenStaff;
