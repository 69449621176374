/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    CardFooter,
    Button,
    Input,
    Col,
    FormGroup,
    Label,
  } from "reactstrap";
  import Select from "react-select";
  import { useEffect, useState } from "react";
  import Swal from "sweetalert2";
  import Pagination from 'react-js-pagination';
  import useDebounce from 'helpers/useDebounce';
  import usePrevious from "helpers/usePrevious";
  import Loader from 'components/Loader/Loader';
  import { getListMatkulPublic } from "services/matakuliah";
  import { getListProdi } from "services/prodi";
import Header from "components/Headers/Header";
  
  
  const Rps = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('')
    const [pageInfo, setPageInfo] = useState({
      totalData: 0,
      totalPage: 0
    })
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const [optionProdi, setOptionProdi] = useState([]);
    const [prodi, setProdi] = useState('');
    const debounceSearch = useDebounce(search, 500);
  
    const prevSearch = usePrevious(debounceSearch)
  
    useEffect(() => {
      getData()
    }, [debounceSearch, prodi])
  
    useEffect(() => {
      getDataProdi()
    }, [])
  
    const getData = async (value, refresh) => {
      setLoading(true)
      let newPage
      if (prevSearch !== debounceSearch || refresh) {
        newPage = 1
      }
      const params = {
        page: value ? value : newPage,
        search,
        prodi: prodi?.value
      }
      const response = await getListMatkulPublic(params)
      if (response.error) {
        Swal.fire({
          icon: 'error',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        setData(response.results.data)
        const pageInfo = {
          totalData: response.results.count,
          totalPage: response.results.pageCount
        }
        setPageInfo(pageInfo)
        if (prevSearch !== debounceSearch || refresh) {
          setPage(newPage)
        }
      }
      setLoading(false)
    }
  
    const handleChangePage = (page) => {
      setPage(page)
      getData(page)
    }
  
    const getDataProdi = async () => {
      const response = await getListProdi()
      if (response.error) {
        Swal.fire({
          icon: 'error',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        const dataProdi = response.results.map(val => {
          return {
            label: val.name,
            value: val.id
          }
        })
        setOptionProdi(dataProdi)
      }
    }
  
    return (
      <>
        {loading && <Loader />}
        <Header />
        <Container className="mt--7">
          <Row className="align-items-center">
            <Col md={1}>
                <div>
                    <img
                        alt="..."
                        src={
                            require("../assets/img/brand/logo_kmc.png").default
                        }
                        width={60}
                        height={60}
                    />
                </div>
            </Col>
            <Col md={6}>
              <FormGroup row className="form-custom align-items-center">
                <Label
                  for="exampleEmail"
                  sm={4}
                  className="text-white"
                >
                  Program Studi Politeknik Kesehatan KMC Kuningan
                </Label>
                <Col sm={8}>
                  <Select
                    className="form-control-alternative"
                    name="program-studi"
                    placeholder="Select Program Studi"
                    options={optionProdi}
                    defaultValue={prodi}
                    onChange={(e) => setProdi(e)}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={7} />
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Daftar Mata Kuliah dan Rencana Pembelajaran Semester</h3>
                  <Row className="mt-3">
                    <Col md="4">
                      <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <div className="table-wrap">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Kode MK</th>
                        <th scope="col">Mata Kuliah</th>
                        <th scope="col">Total SKS</th>
                        <th scope="col">Semester</th>
                        <th scope="col">Program Studi</th>
                        <th scope="col">RPS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? data.map((val, idx) => {
                        return (
                          <tr key={String(idx)}>
                            <th scope="row">
                              {val.kode_mk}
                            </th>
                            <th scope="row">
                              {val.mata_kuliah}
                            </th>
                            <th scope="row">
                              {val.total_sks}
                            </th>
                            <th scope="row">
                              {val.semester}
                            </th>
                            <th scope="row">
                              {val.Prodi.name}
                            </th>
                            <th scope="row">
                              {val.rps ? <Button type="button" onClick={() => window.open(`https://backend.siakadpoltekeskmc.ac.id/rps/${val.rps}`)}>Preview</Button> : '-'}
                            </th>
                          </tr>
                        )
                      }) : (
                        <tr>
                          <th colSpan="6" className="text-center">
                            Data Empty
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <CardFooter className="py-4">
                  {pageInfo.totalData > 0 && (
                    <div className="d-flex justify-content-end mt-4">
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={pageInfo.totalData}
                        pageRangeDisplayed={3}
                        onChange={handleChangePage.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  )}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
  
  export default Rps;
  