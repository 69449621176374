import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "components/Headers/Header";
import { Container } from "reactstrap";
import { Redirect } from "react-router-dom";
import Penilaian from "views/dosen/Penilaian";
import DaftarMahasiswaNilai from "views/dosen/DaftarMahasiswaNilai";

const PenilaianMahasiswa = (props) => {

  const routes = [
    {
      path: "/penilaian-mahasiswa/index",
      component: Penilaian,
      layout: "/dosen"
    },
    {
      path: "/penilaian-mahasiswa/daftar-mahasiswa",
      component: DaftarMahasiswaNilai,
      layout: "/dosen"
    }
  ]

  const routesAkademik = [
    {
      path: "/penilaian-mahasiswa/index",
      component: Penilaian,
      layout: "/akademik"
    },
    {
      path: "/penilaian-mahasiswa/daftar-mahasiswa",
      component: DaftarMahasiswaNilai,
      layout: "/akademik"
    }
  ]

  const routesProdi = [
    {
      path: "/penilaian-mahasiswa/index",
      component: Penilaian,
      layout: "/prodi"
    },
    {
      path: "/penilaian-mahasiswa/daftar-mahasiswa",
      component: DaftarMahasiswaNilai,
      layout: "/prodi"
    }
  ]

  const routesAdmin = [
    {
      path: "/penilaian-mahasiswa/index",
      component: Penilaian,
      layout: "/admin"
    },
    {
      path: "/penilaian-mahasiswa/daftar-mahasiswa",
      component: DaftarMahasiswaNilai,
      layout: "/admin"
    }
  ]

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dosen" || prop.layout === '/akademik' || prop.layout === '/prodi' || prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const route = (props?.location?.pathname === '/dosen/penilaian-mahasiswa' || props?.location?.pathname === '/dosen/penilaian-mahasiswa/index' || props?.location.pathname === '/dosen/penilaian-mahasiswa/daftar-mahasiswa') ? routes : (props?.location?.pathname === '/prodi/penilaian-mahasiswa' || props?.location?.pathname === '/prodi/penilaian-mahasiswa/index' || props?.location.pathname === '/prodi/penilaian-mahasiswa/daftar-mahasiswa') ? routesProdi : (props?.location?.pathname === '/akademik/penilaian-mahasiswa' || props?.location?.pathname === '/akademik/penilaian-mahasiswa/index' || props?.location.pathname === '/akademik/penilaian-mahasiswa/daftar-mahasiswa') ? routesAkademik : (props?.location?.pathname === '/admin/penilaian-mahasiswa' || props?.location?.pathname === '/admin/penilaian-mahasiswa/index' || props?.location.pathname === '/admin/penilaian-mahasiswa/daftar-mahasiswa') ? routesAdmin : []
  const redirect = props?.location?.pathname === '/dosen/penilaian-mahasiswa' ? '/dosen/penilaian-mahasiswa/index' : props?.location?.pathname === '/prodi/penilaian-mahasiswa' ? '/prodi/penilaian-mahasiswa/index' : props?.location?.pathname === '/admin/penilaian-mahasiswa' ? '/admin/penilaian-mahasiswa/index'  : '/akademik/penilaian-mahasiswa/index'

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Switch>
          {getRoutes(route)}
          <Redirect from="*" to={redirect} />
        </Switch>
      </Container>
    </>
  );
};

export default PenilaianMahasiswa;
