import React, { useState } from 'react'
import { CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Button, Form, Container, Card } from 'reactstrap'
import { setUpdateProfileMhs } from 'services/mahasiswa';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loader from 'components/Loader/Loader';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('password', password)

    if (!password || !confirmPassword) {
      if (!password) {
        Swal.fire({
          icon: 'error',
          title: 'Password Baru wajib diisi!!!',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Konfirmasi Password wajib diisi!!!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    } else {
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Password Baru dan Konfirmasi Password harus sama!!!',
          showConfirmButton: false,
          timer: 1500
        })
      } else if (password.length <= 8) {
        Swal.fire({
          icon: 'error',
          title: 'Password Baru harus memiliki 8 karakter!!!',
          showConfirmButton: false,
          timer: 1500
        })
      } else if (confirmPassword.length <= 8) {
        Swal.fire({
          icon: 'error',
          title: 'Konfirmasi Password harus memiliki 8 karakter!!!',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        setLoading(true)
      const response = await setUpdateProfileMhs(formData)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Password Berhasil diganti, silahkan lakukan login ulang!!!'
        }).then(() => {
          Cookies.remove('_T0123')
          Cookies.remove('_R8l')
          Cookies.remove('_P01e')
          history.push('/auth')
        })
      }
      }
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Ganti Password</h3>
              </CardHeader>
              <CardBody>
                <Form onSubmit={submit}>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label>Password Baru</Label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Drop some text here..."
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label>Konfirmasi Password</Label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Drop some text here..."
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">Simpan Perubahan</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ChangePassword