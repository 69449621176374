/* eslint-disable react-hooks/exhaustive-deps */
import Header from "components/Headers/Header";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Button, Card, CardHeader, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { getListKhsMhs } from "services/khs";
import Loader from 'components/Loader/Loader';
import Swal from "sweetalert2";
import { getCountSementer } from "services/prodi";
import { useReactToPrint } from "react-to-print";
import PDFKhs from "components/PDF/Khs";
import Cookies from "js-cookie";
import { getDashboardMhs } from "services/mahasiswa";

const Khs = () => {

  const [data, setData] = useState([]);
  const [semester, setSemester] = useState('');
  const [optionSemester, setOptionSemester] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ipk, setIpk] = useState('');
  const [sks, setSks] = useState('');
  const [wadir, setWadir] = useState('');
  const [akademik, setAkademik] = useState('');
  const [kaprodi, setKaprodi] = useState({
    name: '',
    prodi: ''
  });

  const ref = useRef();

  const getData = async () => {
    setLoading(true)
    const params = {
      semester: semester?.value,
    }
    const response = await getListKhsMhs(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results)
    }
    setLoading(false)
  }

  const getSemester = async () => {
    const response = await getCountSementer()
    if (!response.error) {
      if (response.results !== 0) {
        const result = [...Array(response.results)].map((val, idx) => {
          return {
            label: idx + 1,
            value: idx + 1
          }
        })
        setOptionSemester(result)
      }
    }
  }

  useEffect(() => {
    getData()
  }, [semester])

  useEffect(() => {
    getSemester()
  }, [])

  const printKhs = async () => {
    setLoading(true)
    if (!semester) {
      Swal.fire({
        icon: 'error',
        title: 'Silahkan Pilih Semester terlebih dahulu',
      })
    } else {
      const response = await getDashboardMhs()
      if (data.length > 0 && response.results) {
        setIpk(response?.results?.ipk)
        setKaprodi(response?.results?.kaprodi)
        setWadir(response?.results?.wadir?.name || '')
        setAkademik(response?.results?.akademik?.name || '')
        setSks(response?.results?.total_sks)
        handlePrint()
      } else {
        Swal.fire({
          icon: 'error',
          title: `Nilai Semester ${semester?.value} belum ada`,
        })
      }
    }
    setLoading(false)
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: 'KHS',
  })

  const profileCookies = Cookies.get('_P01e')
  const profileDecode = JSON.parse(atob(profileCookies))

  return (
    <>
      {loading && <Loader />}
      <Header />
      <PDFKhs ref={ref} profile={profileDecode} data={data} semester={semester?.value} ipk={ipk} kaprodi={kaprodi} sks={sks} wadir={wadir} akademik={akademik} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-12 mb-3">
            <FormGroup row className="align-items-center pl-3">
              <Label className="text-white">Semester</Label>
              <Col sm={4} className="pr-5">
                <Select
                  className="form-control-alternative"
                  name="semester"
                  placeholder="Select Semester"
                  onChange={(e) => setSemester(e)}
                  options={optionSemester}
                />
              </Col>
            </FormGroup>
          </div>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="8">
                    <h3 className="mb-0">Kartu Hasil Studi</h3>
                  </Col>
                  <Col md="4">
                    <Button color="primary" type="button" className="float-right ml-2" onClick={printKhs}>Cetak KHS</Button>
                    <Button color="primary" type="button" className="float-right" hidden>Cetak Transkrip</Button>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-wrap">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Kode MK</th>
                      <th scope="col">Mata Kuliah</th>
                      <th scope="col">SKS</th>
                      <th scope="col">Semester</th>
                      <th scope="col">Absensi</th>
                      <th scope="col">Tugas</th>
                      <th scope="col">Quiz</th>
                      <th scope="col">UTS</th>
                      <th scope="col">UAS</th>
                      <th scope="col">Praktikum</th>
                      <th scope="col">Total</th>
                      <th scope="col">Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? data.map((val, idx) => {
                      return (
                        <tr key={String(idx)}>
                          <th scope="row">
                            {val.Matakuliah.kode_mk}
                          </th>
                          <th scope="row">
                            {val.Matakuliah.mata_kuliah}
                          </th>
                          <th scope="row">
                            {val.Matakuliah.total_sks}
                          </th>
                          <th scope="row">
                            {val.Matakuliah.semester}
                          </th>
                          <th scope="row">
                            {val.absensi}
                          </th>
                          <th scope="row">
                            {val.tugas}
                          </th>
                          <th scope="row">
                            {val.quiz}
                          </th>
                          <th scope="row">
                            {val.uts}
                          </th>
                          <th scope="row">
                            {val.uas}
                          </th>
                          <th scope="row">
                            {val.praktikum}
                          </th>
                          <th scope="row">
                            {val.total_nilais}
                          </th>
                          <th scope="row">
                            {val.grade}
                          </th>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <th colSpan="13" className="text-center">
                          Data Empty
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Khs;
