import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const getListMhs = async (params) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa`,
    method: 'GET',
    params,
    token: true
  })
}

export const getDashboardMhs = async () => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/jadwal-kuliah`,
    method: 'GET',
    token: true
  })
}

export const getListMhsDaftar = async (params) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/daftar`,
    method: 'GET',
    params,
    token: true
  })
}

export const getDetailMhs = async (payload) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/detail`,
    method: 'POST',
    data: payload,
    token: true
  })
}

export const setRegisterMhs = async (data) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/register`,
    method: 'POST',
    data
  })
}

export const setPendaftaranMhs = async (data) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/pendaftaran`,
    method: 'POST',
    data
  })
}

export const setUpdateProfileMhs = async (data) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/update`,
    method: 'PUT',
    token: true,
    data
  })
}

export const setUpdateOrtuMhs = async (data) => {

  return callAPI({
    url: `${ROOT_API}ortu-mhs`,
    method: 'POST',
    token: true,
    data
  })
}

export const deleteMahasiswa = async (id) => {

  return callAPI({
    url: `${ROOT_API}mahasiswa/delete/${id}`,
    method: 'DELETE',
    token: true
  })
}
