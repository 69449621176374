import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import ForgotPassword from "views/auth/ForgotPassword";
import Login from "views/auth/Login";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const routes = [
    {
      path: "/login",
      component: Login,
      layout: "/auth"
    },
    {
      path: "/lupa-password",
      component: ForgotPassword,
      layout: "/auth"
    }
  ]

  React.useEffect(() => {
    document.body.classList.add("bg-auth");
    return () => {
      document.body.classList.remove("bg-auth");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="main-content" ref={mainContent}>
      <Row className="m-0 p-lg-7 p-3 align-items-center">
        <Col md={6} className="d-none d-lg-block">
          <img
            alt="..."
            src={
              require("../assets/img/icons/common/auth.svg")
                .default
            }
          />
        </Col>
        <Col md={6}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Col>
      </Row>
    </div>
  );
};

export default Auth;
