/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "components/Loader/Loader";
import ModalPersentaseNilai from "components/Modal/PersentaseNilai";
import useDebounce from "helpers/useDebounce";
import { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Input, Row, Table } from "reactstrap";
import { updateNilaiMhs } from "services/khs";
import { getListMhsKhs } from "services/khs";
import { getDetailPersentaseNilai } from "services/persentaseNilai";
import Swal from "sweetalert2";

const DaftarMahasiswaNilai = () => {

  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [isOpenPersentase, setIsOpenPersentase] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataPersentase, setDataPersentase] = useState({
    absensi: '',
    tugas: '',
    quiz: '',
    uts: '',
    uas: '',
    tugas_akhir: '',
    praktikum: ''
  });

  const debounceSearch = useDebounce(search, 500)

  const togglePersentase = () => setIsOpenPersentase(!isOpenPersentase)

  useEffect(() => {
    getData()
  }, [debounceSearch])

  useEffect(() => {
    getPersentaseNilai()
  }, [])

  const getData = async () => {
    setLoading(true)
    const params = {
      search,
      id_matakuliah: localStorage.getItem('id_matakuliah'),
      tahun_akademik: localStorage.getItem('tahun_akademik')
    }
    const response = await getListMhsKhs(params)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setData(response.results)
    }
    setLoading(false)
  }


  const getPersentaseNilai = async () => {
    setLoading(true)
    const response = await getDetailPersentaseNilai(localStorage.getItem('id_matakuliah'), { tahun_akademik: localStorage.getItem('tahun_akademik') })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (response.results) {
        setDataPersentase(response.results)
      }
    }
    setLoading(false)
  }

  const onChangeTotalGrade = (i) => {
    const total = ((data[i].absensi * (Number(dataPersentase.absensi) / 100)) 
    + (data[i].tugas * Number(dataPersentase.tugas) / 100)
    + (data[i].quiz * Number(dataPersentase.quiz) / 100)
    + (data[i].uts * Number(dataPersentase.uts) / 100)
    + (data[i].uas * Number(dataPersentase.uas) / 100)
    + (data[i].praktikum * Number(dataPersentase.praktikum) / 100)).toFixed(1)
    const grade = (data[i].uts && data[i].uas) ? (total >= 86 ? 'A' : (total >= 80 && total < 86) ? 'A-' : (total >= 75 && total < 80) ? 'B+' : (total >= 70 && total < 75) ? 'B' : (total >= 65 && total < 70) ? 'B-' : (total >= 60 && total < 65) ? 'C+' : (total >= 55 && total < 60) ? 'C' : (total >= 40 && total < 55) ? 'D' : 'E') : null

    let result = [...data]

    result[i].total_nilais = total
    result[i].grade = grade

    setData(result)
  }

  const submit = async () => {
    setLoading(true)
    const response = await updateNilaiMhs(data)
    if (response.error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: response.message
      })
    } else {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: response.message
      }).then(() => {
        getData()
      })
    }
  }

  return (
    <Row>
      <Col xl="12">
        <h2 className="text-white mb-3">{localStorage.getItem('mata_kuliah')}</h2>
      </Col>
      <Col xl="12">
        <Card className="shadow">
          {loading && <Loader />}
          <CardHeader className="border-0">
            <Row className="mt-3">
              <Col md="8">
                <h3 className="mb-0">Daftar Mahasiswa Untuk Penilaian</h3>
              </Col>
              <Col md="4">
                <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
              </Col>
            </Row>
          </CardHeader>
          <div className="table-wrap">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">NIM</th>
                  <th scope="col">Nama Mahasiswa</th>
                  <th scope="col" hidden={!dataPersentase.absensi}>Absensi</th>
                  <th scope="col" hidden={!dataPersentase.tugas}>Tugas</th>
                  <th scope="col" hidden={!dataPersentase.quiz}>Quiz</th>
                  <th scope="col" hidden={!dataPersentase.praktikum}>Praktikum</th>
                  <th scope="col" hidden={!dataPersentase.uts}>UTS</th>
                  <th scope="col" hidden={!dataPersentase.uas}>UAS</th>
                  <th scope="col">Total</th>
                  <th scope="col">Grade</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? data.map((val, idx) => {
                  return (
                    <tr key={String(idx)}>
                      <th scope="row">
                        {idx + 1}
                      </th>
                      <th scope="row">
                        {val.nim}
                      </th>
                      <th scope="row">
                        {val.name}
                      </th>
                      <th scope="row" hidden={!dataPersentase.absensi}>
                        <Input type="number"
                          value={val.absensi}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].absensi = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row" hidden={!dataPersentase.tugas}>
                        <Input type="number"
                          value={val.tugas}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].tugas = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row" hidden={!dataPersentase.quiz}>
                        <Input
                          type="number"
                          value={val.quiz}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].quiz = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row" hidden={!dataPersentase.praktikum}>
                        <Input
                          type="number"
                          value={val.praktikum}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].praktikum = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row" hidden={!dataPersentase.uts}>
                        <Input
                          type="number"
                          value={val.uts}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].uts = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row" hidden={!dataPersentase.uas}>
                        <Input
                          type="number"
                          value={val.uas}
                          onChange={(e) => {
                            let result = [...data]
                            result[idx].uas = e.target.value
                            setData(result)
                            onChangeTotalGrade(idx)
                          }}
                        />
                      </th>
                      <th scope="row">
                        {val.total_nilais}
                      </th>
                      <th scope="row">
                        {val.grade}
                      </th>
                    </tr>
                  )
                }) : (
                  <tr>
                    <th colSpan="11" className="text-center">
                      Data Empty
                    </th>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="p-3">
            <Button className="float-right" onClick={() => submit()} color='primary'>Simpan</Button>
          </div>
        </Card>
      </Col>
      <Col xl="12" className="mt-3">
        <Card className="shadow">
          <ModalPersentaseNilai data={dataPersentase} toggle={togglePersentase} setLoading={setLoading} isOpen={isOpenPersentase} getData={getPersentaseNilai} />
          <CardHeader className="border-0">
            <Row className="mt-3">
              <Col md="12">
                <h3 className="mb-0">Persentase Nilai</h3>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-wrap">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col" hidden={!dataPersentase.absensi}>Absensi</th>
                  <th scope="col" hidden={!dataPersentase.tugas}>Tugas</th>
                  <th scope="col" hidden={!dataPersentase.quiz}>Quiz</th>
                  <th scope="col" hidden={!dataPersentase.uts}>UTS</th>
                  <th scope="col" hidden={!dataPersentase.uas}>UAS</th>
                  <th scope="col" hidden={!dataPersentase.praktikum}>Praktikum</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    1
                  </th>
                  <th scope="row" hidden={!dataPersentase.absensi}>
                    {dataPersentase.absensi}%
                  </th>
                  <th scope="row" hidden={!dataPersentase.tugas}>
                    {dataPersentase.tugas}%
                  </th>
                  <th scope="row" hidden={!dataPersentase.quiz}>
                    {dataPersentase.quiz}%
                  </th>
                  <th scope="row" hidden={!dataPersentase.uts}>
                    {dataPersentase.uts}%
                  </th>
                  <th scope="row" hidden={!dataPersentase.uas}>
                    {dataPersentase.uas}%
                  </th>
                  <th scope="row" hidden={!dataPersentase.praktikum}>
                    {dataPersentase.praktikum}%
                  </th>
                  <td>
                    <Button type="button" size="sm" onClick={() => {
                      togglePersentase()
                    }}>
                      <i className="fa fa-edit" />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default DaftarMahasiswaNilai;
