/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateJadwalUjian } from 'services/jadwalUjian';
import { setUpdateJadwalUjian } from 'services/jadwalUjian';
import { getListMatkul } from 'services/matakuliah';
import moment from 'moment';

const ModalJadwalUjian = ({ isOpen, toggle, created, getData, setLoading, data, listKelas, listProdi }) => {

  const [mataKuliah, setMatakuliah] = useState('');
  const [prodi, setProdi] = useState('');
  const [kelas, setKelas] = useState('');
  const [jenisUjian, setJenisUjian] = useState('');
  const [jamMulai, setJamMulai] = useState('');
  const [jamSelesai, setJamSelesai] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [optionMatkul, setOptionMatkul] = useState([]);

  const listJenisUjian = [
    {
      label: 'UTS',
      value: 'UTS'
    },
    {
      label: 'UAS',
      value: 'UAS'
    },
  ]

  useEffect(() => {
    if (!created && data) {
      if (data.id_mata_kuliah) {
        getDataMatkul(data.id_prodi, data.id_mata_kuliah)
      } else {
        setMatakuliah('')
      }
      if (data.id_prodi) {
        const valueProdi = listProdi.filter(val => val.value === data.id_prodi)[0]
        setProdi(valueProdi)
      } else {
        setProdi('')
      }
      if (data.id_kelas) {
        const valueKelas = listKelas.filter(val => val.value === data.id_kelas)[0]
        setKelas(valueKelas)
      } else {
        setKelas('')
      }
      if (data.tanggal_ujian) {
        setTanggal(moment(data.tanggal_ujian).format('YYYY-MM-DD'))
      } else {
        setTanggal('');
      }
      if (data.jam_mulai) {
        setJamMulai(data.jam_mulai)
      } else {
        setJamMulai('');
      }
      if (data.jam_selesai) {
        setJamSelesai(data.jam_selesai)
      } else {
        setJamSelesai('');
      }
      if (data.jenis_ujian) {
        const value = listJenisUjian.filter(val => val.value === data.jenis_ujian)[0]
        setJenisUjian(value)
      } else {
        setJenisUjian('')
      }
    } else {
      setMatakuliah('');
      setKelas('');
      setJamMulai('');
      setJamSelesai('');
      setProdi('');
      setJenisUjian('');
      setTanggal('');
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const payload = {
      id_mata_kuliah: mataKuliah?.value,
      id_prodi: prodi?.value,
      id_kelas: kelas?.value,
      jenis_ujian: jenisUjian?.value,
      jam_mulai: jamMulai,
      jam_selesai: jamSelesai,
      tanggal_ujian: tanggal,
    }

    if (!mataKuliah || !kelas || !jamMulai || !jamSelesai || !tanggal) {
      Swal.fire({
        icon: 'error',
        title: 'Isian Form harus diisi semua!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateJadwalUjian(payload)
      } else {
        response = await setUpdateJadwalUjian(payload, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  useEffect(() => {
    if (prodi) {
      getDataMatkul(prodi?.value);
    }
  }, [prodi]);

  const getDataMatkul = async (id_prodi, id_matkul) => {
    const response = await getListMatkul({ matkul: true, prodi: id_prodi })
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataMatkul = response.results.map(val => {
        return {
          label: val.mata_kuliah,
          value: val.id
        }
      })
      setOptionMatkul(dataMatkul)
      if (id_matkul) {
        const valueMatkul = dataMatkul.filter(val => val.value === id_matkul)[0]
        setMatakuliah(valueMatkul)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Jadwal Ujian</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jenis Ujian</Label>
                <Select
                  name="hari"
                  placeholder="Select Hari"
                  options={listJenisUjian}
                  defaultValue={jenisUjian}
                  onChange={(e) => setJenisUjian(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Prodi</Label>
                <Select
                  name="matkul"
                  placeholder="Select Prodi"
                  options={listProdi}
                  defaultValue={prodi}
                  onChange={(e) => {
                    setProdi(e)
                    setMatakuliah('');
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Mata Kuliah</Label>
                <Select
                  name="matkul"
                  placeholder="Select Mata Kuliah"
                  options={optionMatkul}
                  value={mataKuliah}
                  onChange={(e) => setMatakuliah(e)}
                  isDisabled={!prodi}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Kelas</Label>
                <Select
                  name="kelas"
                  placeholder="Select Kelas"
                  options={listKelas}
                  defaultValue={kelas}
                  onChange={(e) => setKelas(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Tanggal Ujian</Label>
                <Input type='date' value={tanggal} onChange={(e) => setTanggal(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jam Mulai</Label>
                <Input type='time' value={jamMulai} onChange={(e) => setJamMulai(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jam Selesai</Label>
                <Input type='time' value={jamSelesai} onChange={(e) => setJamSelesai(e.target.value)} />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalJadwalUjian;
