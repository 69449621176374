import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-quill/dist/quill.snow.css';
import 'react-calendar/dist/Calendar.css';

import AdminLayout from "layouts/Admin.js";
import MahasiswaLayout from "layouts/Mahasiswa";
import DosenLayout from "layouts/Dosen";
import ProdiLayout from "layouts/Prodi";
import AkademikLayout from "layouts/Akademik";
import AuthLayout from "layouts/Auth.js";
import LandingPage from "layouts/LandingPage.js";
import PrivateRoute from "helpers/privateRoute";
import Rps from "layouts/Rps";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" render={(props) => <LandingPage {...props} /> } exact />
      <Route path="/rps" render={(props) => <Rps {...props} /> } exact />
      <PrivateRoute
        path="/admin"
        name="admin"
        privateComponent={AdminLayout}
      />
      <PrivateRoute
        path="/dosen"
        name="dosen"
        privateComponent={DosenLayout}
      />
      <PrivateRoute
        path="/mahasiswa"
        name="mahasiswa"
        privateComponent={MahasiswaLayout}
      />
      <PrivateRoute
        path="/prodi"
        name="prodi"
        privateComponent={ProdiLayout}
      />
      <PrivateRoute
        path="/akademik"
        name="akademik"
        privateComponent={AkademikLayout}
      />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
