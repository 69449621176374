/* eslint-disable react-hooks/exhaustive-deps */
import { optionAkreditas, optionJenjang } from 'helpers/optionData';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { setCreateProdi, setUpdateProdi, getListKaprodi } from 'services/prodi';
import Swal from 'sweetalert2';
const ModalProgramStudi = ({ isOpen, toggle, created, getData, setLoading, data }) => {

  const [name, setName] = useState('');
  const [kaprodi, setKaprodi] = useState('');
  const [jenjang, setJenjang] = useState('');
  const [akreditas, setAkreditas] = useState('');
  const [optionKaprodi, setOptionKaprodi] = useState([]);

  const getDataKaprodi = async () => {
    const response = await getListKaprodi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKaprodi = response.results.map(val => {
        return {
          label: val.name,
          value: val.id
        }
      })
      setOptionKaprodi(dataKaprodi)
    }
  }

  useEffect(() => {
    getDataKaprodi()
    if (!created && data) {
      if (data.jenjang) {
        const valueJenjang = optionJenjang.filter(val => val.value === data.jenjang)[0]
        setJenjang(valueJenjang)
      } else {
        setJenjang('')
      }
      if (data.akreditas) {
        const valueAkreditas = optionAkreditas.filter(val => val.value === data.akreditas)[0]
        setAkreditas(valueAkreditas)
      } else {
        setAkreditas('')
      }
      if (data.id_kaprodi) {
        const valueKaprodi = optionKaprodi.filter(val => val.value === data.id_kaprodi)[0]
        setKaprodi(valueKaprodi)
      } else {
        setKaprodi('')
      }
      setName(data.name)
    } else {
      setName('')
      setJenjang('')
      setAkreditas('')
      setKaprodi('')
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const payload = {
      name,
      jenjang: jenjang?.value,
      akreditas: akreditas?.value,
      id_kaprodi: kaprodi?.value
    }

    if (!name) {
      Swal.fire({
        icon: 'error',
        title: 'Nama Program Studi harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateProdi(payload)
      } else {
        response = await setUpdateProdi(payload, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Program Studi</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Nama Program Studi</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Jenjang</Label>
                <Select
                  name="jenjang"
                  placeholder="Select Jenjang Jurusan"
                  options={optionJenjang}
                  defaultValue={jenjang}
                  onChange={(e) => setJenjang(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Akreditas</Label>
                <Select
                  name="akreditas"
                  placeholder="Select Akreditas"
                  options={optionAkreditas}
                  defaultValue={akreditas}
                  onChange={(e) => setAkreditas(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Kepala Prodi</Label>
                <Select
                  name="kepala-prodi"
                  placeholder="Select Kepala Prodi"
                  options={optionKaprodi}
                  defaultValue={kaprodi}
                  onChange={(e) => setKaprodi(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalProgramStudi;
