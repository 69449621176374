import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const getListAktivasi = async (params) => {
  const url = `${ROOT_API}aktivasi`

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const getCheckKrs = async () => {
  const url = `${ROOT_API}aktivasi`

  return callAPI({
    url: `${url}/krs`,
    method: 'GET',
    token: true
  })
}

export const setCreateAktivasi = async (data) => {
  const url = `${ROOT_API}aktivasi`

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateAktivasi = async (data, id) => {
  const url = `${ROOT_API}aktivasi/${id}`

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteAktivasi = async (id) => {
  const url = `${ROOT_API}aktivasi/${id}`

  return callAPI({
    url,
    method: 'DELETE',
    token: true
  })
}