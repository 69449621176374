/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { setCreateTahunAkademik } from 'services/tahunAkademik';
import { setUpdateTahunAkademik } from 'services/tahunAkademik';
import { optionMonth } from 'helpers/optionData';

const ModalTahunAkademik = ({ isOpen, toggle, created, getData, setLoading, data }) => {

    const [name, setName] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');

    useEffect(() => {
        if (!created && data) {
            if (data.start_month) {
                const valueStartMonth = optionMonth.filter(val => val.value === data.start_month)[0]
                setStartMonth(valueStartMonth)
            } else {
                setStartMonth('')
            }
            if (data.end_month) {
                const valueEndMonth = optionMonth.filter(val => val.value === data.end_month)[0]
                setEndMonth(valueEndMonth)
            } else {
                setEndMonth('')
            }
            setName(data.name)
        } else {
            setName('')
            setStartMonth('')
            setEndMonth('')
        }
    }, [isOpen])

    const submit = async (e) => {
        e.preventDefault()
        const payload = {
            name: name,
            start_month: startMonth?.value,
            end_month: endMonth?.value
        }

        if (!name || !startMonth || !endMonth) {
            Swal.fire({
                icon: 'error',
                title: 'Form wajib harus diisi!!!',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            setLoading(true)
            let response
            if (created) {
                response = await setCreateTahunAkademik(payload)
            } else {
                response = await setUpdateTahunAkademik(payload, data.id)
            }
            if (response.error) {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: response.message
                })
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: response.message
                }).then(() => {
                    toggle()
                    getData()
                })
            }
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Tahun Akademik</ModalHeader>
            <ModalBody className="pt-1">
                <Form onSubmit={submit}>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label className='required'>Tahun Akademik</Label>
                                <Input
                                    type="text"
                                    placeholder="Drop some text here..."
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Dari Bulan</Label>
                                <Select
                                    name="dari"
                                    placeholder="Select Dari Bulan"
                                    options={optionMonth}
                                    defaultValue={startMonth}
                                    onChange={(e) => setStartMonth(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Sampai Bulan</Label>
                                <Select
                                    name="dari"
                                    placeholder="Select Sampai Bulan"
                                    options={optionMonth}
                                    defaultValue={endMonth}
                                    onChange={(e) => setEndMonth(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color="primary" type="submit">Simpan Data</Button>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ModalTahunAkademik;
