/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routesDosen from "routesDosen";

const Mahasiswa = (props) => {
  const mainContent = React.useRef(null);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dosen") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routesDosen.length; i++) {
      if (
        props.location.pathname.indexOf(routesDosen[i].layout + routesDosen[i].path) !==
        -1
      ) {
        return routesDosen[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routesDosen}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logo_kmc.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routesDosen)}
          <Redirect from="*" to="/dosen/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Mahasiswa;
