import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}jadwal-kuliah`

export const getListJadwalKuliah = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreateJadwalKuliah = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateJadwalKuliah = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteJadwalKuliah = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}