import { Card, CardBody, Container, Row, Col } from "reactstrap";

const HeaderAdmin = () => {

  return (
    <>
      <div className="headerAdmin bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="mb-0 mb-sm-4 align-items-center">
              <Col lg="6" xl="6">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/common/graduation.svg")
                      .default
                  }
                  className='img-fluid'
                />
              </Col>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row className="align-items-center">
                      <div className="col">
                        <span className="h3 font-weight-bold mb-0">
                          Selamat datang di dashboard sistem akademik Politeknik Kesehatan KMC Kuningan
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-book-reader" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeaderAdmin;
