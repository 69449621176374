import callAPI from './api'


const url = 'https://dev.farizdotid.com/api/daerahindonesia'

export const getListProvinsi = async () => {

  return callAPI({
    url: `${url}/provinsi`,
    method: 'GET'
  })
}

export const getListKabupaten = async (provinsi) => {

  return callAPI({
    url: `${url}/kota?id_provinsi=${provinsi}`,
    method: 'GET'
  })
}

export const getListKecamatan = async (kabupaten) => {

  return callAPI({
    url: `${url}/kecamatan?id_kota=${kabupaten}`,
    method: 'GET'
  })
}

export const getListKelurahan = async (kecamatan) => {

  return callAPI({
    url: `${url}/kelurahan?id_kecamatan=${kecamatan}`,
    method: 'GET'
  })
}
