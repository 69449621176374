import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL

export const getListDosenWali = async (params) => {
  const url = `${ROOT_API}dosen-wali`

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreateDosenWali = async (data) => {
  const url = `${ROOT_API}dosen-wali`

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdateDosenWali = async (data, id) => {
  const url = `${ROOT_API}dosen-wali/${id}`

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: true
  })
}

export const deleteDosenWali = async (id) => {
  const url = `${ROOT_API}dosen-wali/${id}`

  return callAPI({
    url,
    method: 'DELETE',
    token: true
  })
}