import JadwalKuliah from "views/akademik/JadwalKuliah";
import JadwalUjian from "views/akademik/JadwalUjian";
import Index from "views/dosen/Index";
import Profile from "views/dosen/Profile";
import Aktivasi from "views/admin/Aktivasi";
import Perwalian from "views/dosen/Perwalian";
import Mahasiswa from "views/akademik/Mahasiswa";
import MataKuliah from "views/admin/MataKuliah";
import Penilaian from "layouts/PenilaianMahasiswa";

const routesAkademik = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/akademik"
  },
  {
    path: "/mahasiswa",
    name: "Mahasiwa",
    icon: "fa fa-users text-primary",
    component: Mahasiswa,
    layout: "/akademik"
  },
  {
    path: "/perwalian",
    name: "Perwalian",
    icon: "fas fa-book-reader text-primary",
    component: Perwalian,
    layout: "/akademik"
  },
  {
    path: "/mata-kuliah",
    name: "Mata Kuliah",
    icon: "ni ni-books text-primary",
    component: MataKuliah,
    layout: "/akademik"
  },
  {
    path: "/penilaian-mahasiswa",
    name: "Penilaian Mahasiswa",
    icon: "fa fa-layer-group text-primary",
    component: Penilaian,
    layout: "/akademik"
  },
  {
    path: "/jadwal-kuliah",
    name: "Jadwal Kuliah",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: JadwalKuliah,
    layout: "/akademik"
  },
  {
    path: "/jadwal-ujian",
    name: "Jadwal Ujian",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: JadwalUjian,
    layout: "/akademik"
  },
  {
    path: "/aktivasi",
    name: "Aktivasi",
    icon: "ni ni-key-25 text-primary",
    component: Aktivasi,
    layout: "/akademik"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/akademik"
  }
];
export default routesAkademik;
