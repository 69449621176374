import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";
import { setForgotPassword } from "services/auth";
import Swal from "sweetalert2";

const ForgotPassword = () => {

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const history = useHistory()

  const submit = async (e) => {
    e.preventDefault()
    const data = {
      email
    }

    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Email harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      const response = await setForgotPassword(data)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: response.message,
        }).then(() => {
          setLoading(false)
          history.push('/auth/login')
        })
      }
    }
  }

  return (
    <Card className="bg-secondary shadow border-0">
      <CardHeader className="bg-transparent">
        <div className="mt-2 mb-3 px-lg-4">
          <Link to="/auth/login" className="mr-2">
            <i className="fa fa-arrow-left text-primary" />
          </Link>
          <span className="text-primary h2 font-weight-bold">
            Lupa Password
          </span>
        </div>
      </CardHeader>
      <CardBody className="px-lg-5 pb-lg-5">
        <Form role="form" onSubmit={submit}>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <div className="text-right">
            {loading ? (
              <Spinner size="lg" color="dark" />
            ) : (
              <Button className="my-4" color="primary" type="submit">
                Lupa Password
              </Button>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ForgotPassword;
