/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { setUpdatePersentaseNilai } from 'services/persentaseNilai';
import { setCreatePersentaseNilai } from 'services/persentaseNilai';
import Swal from 'sweetalert2';
const ModalPersentaseNilai = ({ isOpen, toggle, setLoading, data, getData }) => {

  const [absensi, setAbsensi] = useState('')
  const [tugas, setTugas] = useState('')
  const [uts, setUts] = useState('')
  const [uas, setUas] = useState('')
  const [praktikum, setPraktikum] = useState('')
  const [tugasAkhir, setTugasAkhir] = useState('')
  const [quiz, setQuiz] = useState('')

  const history = useHistory()

  useEffect(() => {
    setAbsensi(data.absensi ? data.absensi : '')
    setTugas(data.tugas ? data.tugas : '')
    setUts(data.uts ? data.uts : '')
    setUas(data.uas ? data.uas : '')
    setPraktikum(data.praktikum ? data.praktikum : '')
    setTugasAkhir(data.tugas_akhir ? data.tugas_akhir : '')
    setQuiz(data.quiz ? data.quiz : '')
  }, [isOpen])

  const location = useLocation()

  const total = (Number(absensi) || 0) + (Number(tugas) || 0) + (Number(uts) || 0) + (Number(uas) || 0) + (Number(praktikum) || 0) + (Number(tugasAkhir) || 0) + (Number(quiz) || 0)

  const submit = async (e) => {
    e.preventDefault()
    const validasi = {
      uts,
      uas,
    }
    const payload = {
      id_mata_kuliah: Number(localStorage.getItem('id_matakuliah')),
      absensi: parseFloat(absensi),
      tugas: parseFloat(tugas),
      uts: parseFloat(uts),
      uas: parseFloat(uas),
      praktikum: parseFloat(praktikum),
      tugas_akhir: parseFloat(tugasAkhir),
      quiz: parseFloat(quiz),
      tahun_akademik: localStorage.getItem('tahun_akademik')
    }
    let errorValidasi
    Object.keys(validasi).some(keys => {
      if (validasi[keys] === '') {
        if (keys.search('_') >= 0) {
          errorValidasi = `${keys.split('_').join(' ')} wajib diisi!!!`
        } else {
          errorValidasi = `${keys.charAt(0).toUpperCase() + keys.slice(1)} wajib diisi!!!`
        }
      }
      return validasi[keys] === '' || validasi[keys] === null
    })
    if (errorValidasi) {
      Swal.fire({
        icon: 'error',
        title: errorValidasi,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      const response = data.id ? await setUpdatePersentaseNilai(payload, data.id) : await setCreatePersentaseNilai(payload)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          if (!data.id) {
            const split = location?.pathname.split('/')
            history.push(`/${split[1]}/penilaian-mahasiswa/daftar-mahasiswa`)
          } else {
            getData()
          }
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>Persentase Penilaian</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Absensi (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={absensi}
                      onChange={(e) => setAbsensi(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>UTS (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={uts}
                      onChange={(e) => setUts(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>UAS (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={uas}
                      onChange={(e) => setUas(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Praktikum (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={praktikum}
                      onChange={(e) => setPraktikum(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Tugas (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={tugas}
                      onChange={(e) => setTugas(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Quiz (%)</Label>
                    <Input
                      type="number"
                      placeholder="Drop some text here..."
                      value={quiz}
                      onChange={(e) => setQuiz(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <h4 className='text-danger'>{total < 100 ? 'Persentase masih kurang dari 100%' : (total > 100 ? 'Persentase lebih dari 100%' : '')}</h4>
          <Button color="primary" type="submit" disabled={total < 100 || total > 100}>Simpan Perubahan</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalPersentaseNilai;
