/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Button, Form, Container, Card } from 'reactstrap'
import Select from 'react-select'
import { getListProvinsi, getListKabupaten, getListKecamatan, getListKelurahan } from 'services/daerahIndonesia';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';
import { optionAgama } from 'helpers/optionData';
import Loader from 'components/Loader/Loader';
import { setUpdateProfileDosenStaff } from 'services/dosenStaff';
import { useHistory } from 'react-router-dom';
import Header from 'components/Headers/Header';
import { getDetailDosenStaff } from 'services/dosenStaff';

const ProfileDosen = () => {

  const [dosenStaff, setDosenStaff] = useState({
    name: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    agama: '',
    nik: '',
    alamat: '',
    provinsi: '',
    kabupaten: '',
    kecamatan: '',
    kelurahan: '',
    kewarganegaraan: 'Indonesia',
    kode_pos: '',
    telepon: ''
  });
  const [loading, setLoading] = useState(false);
  const [optionProvinsi, setOptionProvinsi] = useState([]);
  const [optionKabupaten, setOptionKabupaten] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionKelurahan, setOptionKelurahan] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const history = useHistory();

  const getDataProvinsi = async (provinsi) => {
    const response = await getListProvinsi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProvinsi = response.provinsi.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionProvinsi(dataProvinsi)
      if (provinsi) {
        const resultProvinsi = dataProvinsi.filter(val => val.value === Number(provinsi))[0]
        return resultProvinsi
      }
    }
  }

  const getDataKabupaten = async (provinsi, kabupaten) => {
    const response = await getListKabupaten(provinsi)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKabupaten = response.kota_kabupaten.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKabupaten(dataKabupaten)
      if (kabupaten) {
        const resultKabupaten = dataKabupaten.filter(val => val.value === Number(kabupaten))[0]
        return resultKabupaten
      }
    }
  }

  const getDataKecamatan = async (kabupaten, kecamatan) => {
    const response = await getListKecamatan(kabupaten)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKecamatan = response.kecamatan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKecamatan(dataKecamatan)
      if (kecamatan) {
        const resultKecamatan = dataKecamatan.filter(val => val.value === Number(kecamatan))[0]
        return resultKecamatan
      }
    }
  }

  const getDataKelurahan = async (kecamatan, kelurahan) => {
    const response = await getListKelurahan(kecamatan)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKelurahan = response.kelurahan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKelurahan(dataKelurahan)
      if (kelurahan) {
        const resultKelurahan = dataKelurahan.filter(val => val.value === Number(kelurahan))[0]
        return resultKelurahan
      }
    }
  }

  const initialData = async () => {
    const profileCookies = Cookies.get('_P01e')
    const profileDecode = JSON.parse(atob(profileCookies))
    let provinsi = ''
    let kabupaten = ''
    let kecamatan = ''
    let kelurahan = ''
    if (profileDecode.provinsi) {
      provinsi = await getDataProvinsi(profileDecode.provinsi)
    }
    if (profileDecode.kabupaten) {
      kabupaten = await getDataKabupaten(profileDecode.provinsi, profileDecode.kabupaten)
    }
    if (profileDecode.kecamatan) {
      kecamatan = await getDataKecamatan(profileDecode.kabupaten, profileDecode.kecamatan)
    }
    if (profileDecode.kelurahan) {
      kelurahan = await getDataKelurahan(profileDecode.kecamatan, profileDecode.kelurahan)
    }
    setDosenStaff({
      ...dosenStaff,
      name: profileDecode.name || '',
      tempat_lahir: profileDecode.tempat_lahir || '',
      tanggal_lahir: profileDecode.tanggal_lahir ? moment(profileDecode.tanggal_lahir).format('YYYY-MM-DD') : '',
      jenis_kelamin: profileDecode.jenis_kelamin || '',
      agama: profileDecode.agama ? optionAgama.filter(val => val.value === profileDecode.agama)[0] : '',
      nik: profileDecode.nik || '',
      telepon: profileDecode.telepon || '',
      alamat: profileDecode.alamat || '',
      provinsi,
      kabupaten,
      kecamatan,
      kelurahan,
      kewarganegaraan: 'Indonesia',
      kode_pos: profileDecode.kode_pos || ''
    })
  }

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', dosenStaff.name)
    formData.append('tempat_lahir', dosenStaff.tempat_lahir)
    formData.append('tanggal_lahir', dosenStaff.tanggal_lahir)
    formData.append('jenis_kelamin', dosenStaff.jenis_kelamin)
    formData.append('agama', dosenStaff.agama?.value)
    formData.append('nik', dosenStaff.nik)
    formData.append('telepon', dosenStaff.telepon)
    formData.append('alamat', dosenStaff.alamat)
    formData.append('kelurahan', dosenStaff.kelurahan?.value)
    formData.append('kecamatan', dosenStaff.kecamatan?.value)
    formData.append('kabupaten', dosenStaff.kabupaten?.value)
    formData.append('provinsi', dosenStaff.provinsi?.value)
    formData.append('kode_pos', dosenStaff.kode_pos)
    if (password) {
      formData.append('password', password)
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Konfirmasi password harus sama dengan Password Baru'
        })
        return false
      }
    }

    let errorValidasi
    Object.keys(dosenStaff).some(keys => {
      if (dosenStaff[keys] === '') {
        if (keys.search('_') >= 0) {
          errorValidasi = `${keys.split('_').join(' ')} wajib diisi!!!`
        } else {
          errorValidasi = `${keys.charAt(0).toUpperCase() + keys.slice(1)} wajib diisi!!!`
        }
      }
      return dosenStaff[keys] === '' || dosenStaff[keys] === null
    })

    if (errorValidasi) {
      Swal.fire({
        icon: 'error',
        title: errorValidasi,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      const response = await setUpdateProfileDosenStaff(formData)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        if (password) {
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Profile Berhasil diupdate, silahkan lakukan login ulang!!!'
          }).then(() => {
            Cookies.remove('_T0123')
            Cookies.remove('_R8l')
            Cookies.remove('_P01e')
            history.push('/auth')
          })
        } else {
          setLoading(false)
          const profileCookies = Cookies.get('_P01e')
          const profileDecode = JSON.parse(atob(profileCookies))
          const responseResult = await getDetailDosenStaff({ id: profileDecode.id })
          const profileBase = btoa(JSON.stringify(responseResult.results))
          Cookies.set('_P01e', profileBase, { expires: 1 })
          Swal.fire({
            icon: 'success',
            title: 'Profile Berhasil diupdate!!!'
          })
        }
      }
    }
  }

  useEffect(() => {
    getDataProvinsi()
    initialData()
  }, [])

  const cookiesProfil = Cookies.get('_P01e')
  const profile = JSON.parse(atob(cookiesProfil))

  return (
    <>
      <Header />
      {/* Page content */}
      {loading && <Loader />}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardBody>
                <div className="text-center">
                  <h3>
                    {profile.name}
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    NIP: {profile.nip !== 'undefined' ? profile.nip : '-'}
                  </div>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    NIDN: {profile.nidn !== 'undefined' ? profile.nidn : '-'}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Sunting Profile</h3>
              </CardHeader>
              <CardBody>
                <Form onSubmit={submit}>
                  <Row>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Nama</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={dosenStaff.name}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, name: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tempat Lahir</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={dosenStaff.tempat_lahir}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, tempat_lahir: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tanggal Lahir</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="date"
                              value={dosenStaff.tanggal_lahir}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, tanggal_lahir: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Jenis Kelamin</Label>
                            <div className="py-2 pb-3">
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={dosenStaff.jenis_kelamin === "Laki-laki"}
                                  value="Laki-laki"
                                  onChange={(e) => setDosenStaff({ ...dosenStaff, jenis_kelamin: e.target.value })}
                                />
                                <Label check>
                                  Laki-laki
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={dosenStaff.jenis_kelamin === "Perempuan"}
                                  value="Perempuan"
                                  onChange={(e) => setDosenStaff({ ...dosenStaff, jenis_kelamin: e.target.value })}
                                />
                                <Label check>
                                  Perempuan
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Agama
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="agama"
                              placeholder="Select Agama"
                              value={dosenStaff.agama}
                              options={optionAgama}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, agama: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>NIK</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={dosenStaff.nik}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, nik: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Telepon</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={dosenStaff.telepon}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, telepon: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Alamat</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={dosenStaff.alamat}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, alamat: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Provinsi</Label>
                            <Select
                              className="form-control-alternative"
                              name="provinsi"
                              placeholder="Select Provinsi"
                              value={dosenStaff.provinsi}
                              options={optionProvinsi}
                              onChange={(e) => {
                                setDosenStaff({ ...dosenStaff, provinsi: e, kabupaten: '', kecamatan: '', kelurahan: '' })
                                getDataKabupaten(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Kabupaten / Kota
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="kabupaten"
                              placeholder="Select Kabupaten / Kota"
                              value={dosenStaff.kabupaten}
                              isDisabled={!dosenStaff.provinsi}
                              options={optionKabupaten}
                              onChange={(e) => {
                                setDosenStaff({ ...dosenStaff, kabupaten: e, kecamatan: '' })
                                getDataKecamatan(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Kecamatan
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="kecamatan"
                              placeholder="Select Kecamatan"
                              value={dosenStaff.kecamatan}
                              isDisabled={!dosenStaff.kabupaten}
                              options={optionKecamatan}
                              onChange={(e) => {
                                setDosenStaff({ ...dosenStaff, kecamatan: e, kelurahan: '' })
                                getDataKelurahan(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Kelurahan / Desa</Label>
                            <Select
                              className="form-control-alternative"
                              name="kelurahan"
                              placeholder="Select Kelurahan"
                              value={dosenStaff.kelurahan}
                              isDisabled={!dosenStaff.kecamatan}
                              options={optionKelurahan}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, kelurahan: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Kewarganegaraan</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              value="Indonesia"
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Kode Pos</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={dosenStaff.kode_pos}
                              onChange={(e) => setDosenStaff({ ...dosenStaff, kode_pos: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Ubah Password
                  </h6>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label>Password Baru</Label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Drop some text here..."
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label>Konfirmasi Password</Label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Drop some text here..."
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">Simpan Perubahan</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileDosen;
