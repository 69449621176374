import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}persentase-nilai`

export const getListPersentaseNilai = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const getDetailPersentaseNilai = async (id, params) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'GET',
    token: true,
    params
  })
}

export const setCreatePersentaseNilai = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdatePersentaseNilai = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deletePersentaseNilai = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}