/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateRole, setUpdateRole } from 'services/role';

const ModalLevelUser = ({ isOpen, toggle, created, getData, setLoading, data }) => {

  const [level, setLevel] = useState('');

  useEffect(() => {
    if (!created && data) {
      setLevel(data.name)
    } else {
      setLevel('')
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const payload = {
      name: level
    }

    if (!level) {
      Swal.fire({
        icon: 'error',
        title: 'Level harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateRole(payload)
      } else {
        response = await setUpdateRole(payload, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>{created ? "Buat" : "Update"} Level User</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Level</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  onChange={(e) => setLevel(e.target.value)}
                  value={level}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalLevelUser;
