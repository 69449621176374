import moment from 'moment'
import React, { forwardRef } from 'react'
import { Col, Row, Table } from 'reactstrap'

const PDFKrs = forwardRef((props, ref) => {
    return (
        <div className='d-none'>
            <div ref={ref} className="p-5">
                <div className='d-flex mb-3 w-100'>
                    <div>
                        <img
                            alt="..."
                            src={
                                require("../../assets/img/brand/logo_kmc.png").default
                            }
                            width={60}
                            height={60}
                        />
                    </div>
                    <div className="ml-5">
                        <h1>
                            POLITEKNIK KESEHATAN KMC KUNINGAN
                        </h1>
                        <p className='mb-0'>
                            SK KEMENDIKBUDRISTEK RI Nomor: 248/D/OT/OT/2021 Tanggal 30 Desember 2021
                        </p>
                        <p className='mb-0'>
                            Alamat Jl. Pramuka No. 65 Kelurahan Purwawinangun
                        </p>
                        <p className='mb-1'>
                            Kecamatan Kuningan, Kabupaten Kuningan
                        </p>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/whatsapp.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>081324699698</p>
                            </div>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/email.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>poltekeskmc@gmail.com</p>
                            </div>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt="..."
                                    src={
                                        require("../../assets/img/brand/facebook.png").default
                                    }
                                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                                />
                                <p className='ml-1 mb-0'>Poltekes KMC Kuningan</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='mb-3'>
                    <h4 className='text-center'>
                        KARTU RENCANA STUDI
                    </h4>
                    <h4 className='text-center'>
                        POLITEKNIK KESEHATAN KMC KUNINGAN
                    </h4>
                </div>
                <Row className='mb-3'>
                    <Col lg={6} md={6} xs={6} sm={6}>
                        <Row className='px-0'>
                            <Col lg={3} md={3} xs={3} sm={3}>
                                <h6>NIM</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.nim}</h6>
                            </Col>
                            <Col lg={3} md={3} xs={3} sm={3}>
                                <h6>Nama</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.name}</h6>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} xs={6} sm={6}>
                        <Row className='px-0'>
                            <Col lg={3} md={3} xs={3} sm={3}>
                                <h6>Program Studi</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.prodi}</h6>
                            </Col>
                            <Col lg={3} md={3} xs={3} sm={3}>
                                <h6>Dosen Wali</h6>
                            </Col>
                            <Col lg={9} md={9} xs={9} sm={9}>
                                <h6>: {props.profile.nama_dosen_wali}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='table-wrap'>
                    <Table className="align-items-center" bordered>
                        <thead>
                            <tr>
                                <th scope="col">Kode MK</th>
                                <th scope="col" style={{ width: 200 }}>Mata Kuliah</th>
                                <th scope="col">SKS</th>
                                <th scope="col">Semester</th>
                                <th scope="col">Jadwal Kuliah</th>
                                <th scope="col">Dosen Pengajar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.length > 0 ? props.data.map((val, idx) => {
                                return (
                                    <tr key={String(idx)}>
                                        <th scope="row">
                                            {val.kode_mk}
                                        </th>
                                        <th scope="row" style={{ width: 200 }}>
                                            {val.mata_kuliah}
                                        </th>
                                        <th scope="row">
                                            {val.total_sks}
                                        </th>
                                        <th scope="row">
                                            {val.semester}
                                        </th>
                                        <th scope="row">
                                            {val.hari}, {val.jam}
                                        </th>
                                        <th scope="row">
                                            {val.nama_dosen}
                                        </th>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <th colSpan="10" className="text-center">
                                        Data Empty
                                    </th>
                                </tr>
                            )}
                            <tr>
                                <th scope="row" colSpan={2}>
                                    Jumlah SKS
                                </th>
                                <th scope="row">
                                    {props.totalSks}
                                </th>
                                <th colSpan={3} />
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className='ttd-krs'>
                        <p className='mb-0 text-center'>Kuningan, {moment().format('DD MMMM YYYY')}</p>
                        <p className='text-center mb-5'>Kepala Bagian Akademik</p>
                        <p className='text-center mb-5'>{props?.kabagAkademik?.name}</p>
                    </div>
                    <div className='ttd-krs'>
                        <p className='mb-0 text-center'>Kuningan, {moment().format('DD MMMM YYYY')}</p>
                        <p className='text-center mb-0'>Ketua Program Prodi</p>
                        <p className='text-center mb-5'>{props?.kaprodi?.prodi}</p>
                        <p className='text-center mb-5'>{props?.kaprodi?.name}</p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default PDFKrs