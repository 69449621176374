import Index from "views/dosen/Index";
import Profile from "views/dosen/Profile";
import Perwalian from "views/dosen/Perwalian";
import Penilaian from "layouts/PenilaianMahasiswa";

const routesDosen = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/dosen"
  },
  {
    path: "/perwalian",
    name: "Perwalian",
    icon: "fas fa-book-reader text-primary",
    component: Perwalian,
    layout: "/dosen"
  },
  {
    path: "/penilaian-mahasiswa",
    name: "Penilaian Mahasiswa",
    icon: "fa fa-layer-group text-primary",
    component: Penilaian,
    layout: "/dosen"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/dosen"
  }
];
export default routesDosen;
