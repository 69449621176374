import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}khs`

export const getListKhsMhs = async (params) => {

  return callAPI({
    url: `${url}/list-mhs`,
    method: 'GET',
    params,
    token: true
  })
}

export const getListKhsMhsAdmin = async (params) => {

  return callAPI({
    url: `${url}/list-mhs-admin`,
    method: 'GET',
    params,
    token: true
  })
}

export const getListMkKhs = async (params) => {

  return callAPI({
    url: `${url}/list-mk`,
    method: 'GET',
    params,
    token: true
  })
}

export const getListMhsKhs = async (params) => {

  return callAPI({
    url: `${url}/list-mhs-khs`,
    method: 'GET',
    params,
    token: true
  })
}

export const updateNilaiMhs = async (data) => {

  return callAPI({
    url: `${url}/update-nilai`,
    method: 'POST',
    data,
    token: true
  })
}