import Header from "components/Headers/Header";
import { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, CardFooter, CardHeader, Col, Container, FormGroup, Input, Row, Table } from "reactstrap";
import { getListKrsMhs, setPengajuanKrsMhs } from "services/krs";
import Swal from "sweetalert2";
import Loader from 'components/Loader/Loader';
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import PDFKrs from "components/PDF/Krs";
import { getListPrintKrsMhs } from "services/krs";
import Cookies from 'js-cookie';
import { getCheckKrs } from "services/aktivasi";
import { useHistory } from "react-router-dom";
import { getTahunAkademik } from "services/tahunAkademik";

const Krs = () => {
  const [data, setData] = useState([]);
  const [dataPrint, setDataPrint] = useState([]);
  const [kaprodi, setKaprodi] = useState({
    name: '',
    prodi: ''
  });
  const [kabagAkademik, setKabagAkademik] = useState({
    name: '',
  });
  const [totalSks, setTotalSks] = useState(0);
  const [ambilMk, setAmbilMk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ajaran, setAjaran] = useState('');

  const ref = useRef();

  const history = useHistory()


  const getData = useCallback(async () => {
    setLoading(true)
    const res = await getCheckKrs()
    const akademik = await getTahunAkademik()
    if (res.results) {
      const response = await getListKrsMhs()
      if (response.error) {
        Swal.fire({
          icon: 'error',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        setAjaran(akademik?.results?.name)
        setData(response.results)
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Belum Waktunya melakukan KRS',
      }).then(() => {
        history.push('/mahasiswa')
      })
    }
    setLoading(false)
  }, [history])

  const submitKrs = async () => {
    setLoading(true)
    const response = await setPengajuanKrsMhs(ambilMk)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire('Success', response.message, 'success').then(() => {
        getData()
        setAmbilMk([])
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [getData])

  const status = (val) => {
    if (val.status) {
      return (
        <Badge color="" className="badge-dot mr-4">
          <i className={val.status === "pengajuan" ? "bg-warning" : (val.status === "diterima" ? "bg-success" : "bg-danger")} />
          {val.status}
        </Badge>
      )
    }
    return <div></div>
  }

  const checkAmbilMk = (val) => {
    const check = ambilMk.find(mk => mk === val.id)
    if (check) {
      const filterMk = ambilMk.filter(mk => mk !== val.id)
      setAmbilMk(filterMk)
    } else {
      setAmbilMk([...ambilMk, val.id])
    }
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: 'KRS',
  })

  const printKrs = async () => {
    setLoading(true)
    const response = await getListPrintKrsMhs()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (response?.results?.data?.length > 0) {
        setDataPrint(response?.results?.data)
        setTotalSks(response?.results?.totalSks)
        setKaprodi(response?.results?.kaprodi)
        setKabagAkademik(response?.results?.kabagAkademik)
        setLoading(false)
        handlePrint()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Anda belum melakukan pengajuan KRS',
        })
        setLoading(false)
        return
      }
    }
  }

  const profileCookies = Cookies.get('_P01e')
  const profileDecode = JSON.parse(atob(profileCookies))

  return (
    <>
      {loading && <Loader />}
      <Header />
      <PDFKrs ref={ref} data={dataPrint} profile={profileDecode} totalSks={totalSks} kaprodi={kaprodi} kabagAkademik={kabagAkademik} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-12 mb-3">
            <Card color="info" className="shadow p-3">
              <span className="text-white mb-2">Perhatian</span>
              <ol className="text-white mb-0">
                <li>
                  Pastikan untuk memilih matakuliah sesuai semester perkuliahan anda saat ini
                </li>
                <li>
                  Apabila anda ingin mengulang mata kuliah pilih mata kuliah yang berada di sebelah kanan
                </li>
                <li>
                  Apabila mata kuliah sudah di terima oleh dosen wali, maka tidak bisa diubah
                </li>
                <li>
                  Pastikan untuk selalu memeriksa status pengisian KRS apabila ditolak oleh dosen wali maka mahasiswa dapat menghapus KRS tersebut
                </li>
              </ol>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="mt-3">
                  <Col md="8">
                    <h3 className="mb-0">Kartu Rencana Studi : Semester {ajaran}</h3>
                  </Col>
                  <Col md="4">
                    <Button color="primary" type="button" className="float-right" onClick={printKrs}>Cetak KRS</Button>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-wrap">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ambil Mata Kuliah</th>
                      <th scope="col">Kode MK</th>
                      <th scope="col">Mata Kuliah</th>
                      <th scope="col">SKS</th>
                      <th scope="col">Semester</th>
                      <th scope="col">Jadwal Kuliah</th>
                      <th scope="col">Dosen Pengajar</th>
                      <th scope="col">Tanggal Pengisian</th>
                      <th scope="col">Status</th>
                      <th scope="col">Ulang Mata Kuliah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? data.map((val, idx) => {
                      return (
                        <tr key={String(idx)}>
                          <th scope="row" style={{ width: 50 }}>
                            <FormGroup check className="pb-4">
                              <Input type="checkbox" disabled={val.status ? (val.status === 'ditolak' ? false : true) : val.valid} onChange={() => checkAmbilMk(val)} checked={ambilMk.find(mk => mk === val.id) ? true : false} />
                            </FormGroup>
                          </th>
                          <th scope="row">
                            {val.kode_mk}
                          </th>
                          <th scope="row">
                            {val.mata_kuliah}
                          </th>
                          <th scope="row">
                            {val.total_sks}
                          </th>
                          <th scope="row">
                            {val.semester}
                          </th>
                          <th scope="row">
                            {val.hari}, {val.jam}
                          </th>
                          <th scope="row">
                            {val.nama_dosen}
                          </th>
                          <th scope="row">
                            {val.tanggal_pengajuan && moment(val.tanggal_pengajuan).format('DD-MM-YYYY HH:mm:ss')}
                          </th>
                          <th scope="row">
                            {status(val)}
                          </th>
                          <th scope="row">
                            <FormGroup check className="pb-4">
                              <Input type="checkbox" disabled={!val.ulang_matkul} />
                            </FormGroup>
                          </th>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <th colSpan="10" className="text-center">
                          Data Empty
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <Button color="success" type="button" className="float-right" disabled={ambilMk.length === 0} onClick={() => submitKrs()}>Ajukan KRS</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Krs;
