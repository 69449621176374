/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Button, Form, Container, Card } from 'reactstrap'
import Select from 'react-select'
import { getListProvinsi, getListKabupaten, getListKecamatan, getListKelurahan } from 'services/daerahIndonesia';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';
import { optionAgama, optionPendidikan, optionPekerjaan, optionPenghasilan } from 'helpers/optionData';
import ModalPreview from "components/Modal/Preview";
import Loader from 'components/Loader/Loader';
import { setUpdateProfileMhs, setUpdateOrtuMhs } from 'services/mahasiswa';
import { useHistory } from 'react-router-dom';

const SuntingProfile = () => {

  const [mahasiswa, setMahasiswa] = useState({
    name: '',
    nisn: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    agama: '',
    status_bekerja: '',
    nik: '',
    handphone: '',
    jenis_tinggal: '',
    alamat: '',
    rt: '',
    rw: '',
    provinsi: '',
    kabupaten: '',
    kecamatan: '',
    kelurahan: '',
    kewarganegaraan: 'Indonesia',
    kode_pos: '',
    telepon: '',
    alat_transportasi: '',
    path_ijazah: ''
  });
  const [ortu, setOrtu] = useState({
    id_mahasiswa: '',
    nama_ayah: '',
    nik_ayah: '',
    tempat_lahir_ayah: '',
    tanggal_lahir_ayah: null,
    telepon_ayah: '',
    pendidikan_ayah: '',
    pekerjaan_ayah: '',
    penghasilan_ayah: '',
    nama_ibu: '',
    nik_ibu: '',
    tempat_lahir_ibu: '',
    tanggal_lahir_ibu: null,
    telepon_ibu: '',
    pendidikan_ibu: '',
    pekerjaan_ibu: '',
    penghasilan_ibu: ''
  });
  const [isOpen, setIsOpen] = useState(false);
  const [fileIjazah, setFileIjazah] = useState('');
  const [loading, setLoading] = useState(false);
  const [previewIjazah, setPreviewIjazah] = useState('');
  const [optionProvinsi, setOptionProvinsi] = useState([]);
  const [optionKabupaten, setOptionKabupaten] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionKelurahan, setOptionKelurahan] = useState([]);

  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen)

  const getDataProvinsi = async (provinsi) => {
    const response = await getListProvinsi()
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataProvinsi = response.provinsi.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionProvinsi(dataProvinsi)
      if (provinsi) {
        const resultProvinsi = dataProvinsi.filter(val => val.value === Number(provinsi))[0]
        return resultProvinsi
      }
    }
  }

  const getDataKabupaten = async (provinsi, kabupaten) => {
    const response = await getListKabupaten(provinsi)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKabupaten = response.kota_kabupaten.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKabupaten(dataKabupaten)
      if (kabupaten) {
        const resultKabupaten = dataKabupaten.filter(val => val.value === Number(kabupaten))[0]
        return resultKabupaten
      }
    }
  }

  const getDataKecamatan = async (kabupaten, kecamatan) => {
    const response = await getListKecamatan(kabupaten)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKecamatan = response.kecamatan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKecamatan(dataKecamatan)
      if (kecamatan) {
        const resultKecamatan = dataKecamatan.filter(val => val.value === Number(kecamatan))[0]
        return resultKecamatan
      }
    }
  }

  const getDataKelurahan = async (kecamatan, kelurahan) => {
    const response = await getListKelurahan(kecamatan)
    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: response.message,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      const dataKelurahan = response.kelurahan.map(val => {
        return {
          label: val.nama,
          value: val.id
        }
      })
      setOptionKelurahan(dataKelurahan)
      if (kelurahan) {
        const resultKelurahan = dataKelurahan.filter(val => val.value === Number(kelurahan))[0]
        return resultKelurahan
      }
    }
  }

  const initialData = async () => {
    const profileCookies = Cookies.get('_P01e')
    const profileDecode = JSON.parse(atob(profileCookies))
    let provinsi = ''
    let kabupaten = ''
    let kecamatan = ''
    let kelurahan = ''
    if (profileDecode.provinsi) {
      provinsi = await getDataProvinsi(profileDecode.provinsi)
    }
    if (profileDecode.kabupaten) {
      kabupaten = await getDataKabupaten(profileDecode.provinsi, profileDecode.kabupaten)
    }
    if (profileDecode.kecamatan) {
      kecamatan = await getDataKecamatan(profileDecode.kabupaten, profileDecode.kecamatan)
    }
    if (profileDecode.kelurahan) {
      kelurahan = await getDataKelurahan(profileDecode.kecamatan, profileDecode.kelurahan)
    }
    setMahasiswa({
      ...mahasiswa,
      name: profileDecode.name || '',
      nisn: profileDecode.nisn || '',
      rt: profileDecode.rt || '',
      rw: profileDecode.rw || '',
      jenis_tinggal: profileDecode.jenis_tinggal || '',
      alat_transportasi: profileDecode.alat_transportasi || '',
      tempat_lahir: profileDecode.tempat_lahir || '',
      tanggal_lahir: profileDecode.tanggal_lahir ? moment(profileDecode.tanggal_lahir).format('YYYY-MM-DD') : '',
      jenis_kelamin: profileDecode.jenis_kelamin || '',
      agama: profileDecode.agama ? optionAgama.filter(val => val.value === profileDecode.agama)[0] : '',
      status_bekerja: profileDecode.status_bekerja || '',
      nik: profileDecode.nik || '',
      handphone: profileDecode.handphone || '',
      telepon: profileDecode.telepon || '',
      alamat: profileDecode.alamat || '',
      provinsi,
      kabupaten,
      kecamatan,
      kelurahan,
      kewarganegaraan: 'Indonesia',
      kode_pos: profileDecode.kode_pos || '',
      path_ijazah: profileDecode.path_ijazah || ''
    })
    setOrtu({
      ...ortu,
      id_mahasiswa: profileDecode.id,
      nama_ayah: profileDecode.nama_ayah || '',
      nik_ayah: profileDecode.nik_ayah || '',
      tempat_lahir_ayah: profileDecode.tempat_lahir_ayah,
      tanggal_lahir_ayah: profileDecode.tanggal_lahir_ayah ? moment(profileDecode.tanggal_lahir_ayah).format('YYYY-MM-DD') : null,
      telepon_ayah: profileDecode.telepon_ayah || '',
      pendidikan_ayah: profileDecode.pendidikan_ayah ? optionPendidikan.filter(val => val.value === profileDecode.pendidikan_ayah)[0] : '',
      pekerjaan_ayah: profileDecode.pekerjaan_ayah ? optionPekerjaan.filter(val => val.value === profileDecode.pekerjaan_ayah)[0] : '',
      penghasilan_ayah: profileDecode.penghasilan_ayah ? optionPenghasilan.filter(val => val.value === profileDecode.penghasilan_ayah)[0] : '',
      nama_ibu: profileDecode.nama_ibu || '',
      nik_ibu: profileDecode.nik_ibu || '',
      tempat_lahir_ibu: profileDecode.tempat_lahir_ibu || '',
      tanggal_lahir_ibu: profileDecode.tanggal_lahir_ibu ? moment(profileDecode.tanggal_lahir_ibu).format('YYYY-MM-DD') : null,
      telepon_ibu: profileDecode.telepon_ibu || '',
      pendidikan_ibu: profileDecode.pendidikan_ibu ? optionPendidikan.filter(val => val.value === profileDecode.pendidikan_ibu)[0] : '',
      pekerjaan_ibu: profileDecode.pekerjaan_ibu ? optionPekerjaan.filter(val => val.value === profileDecode.pekerjaan_ibu)[0] : '',
      penghasilan_ibu: profileDecode.penghasilan_ibu ? optionPenghasilan.filter(val => val.value === profileDecode.penghasilan_ibu)[0] : ''
    })
  }

  const onChangeIjazah = (e) => {
    if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
      setFileIjazah(e.target.files[0]);
      setPreviewIjazah(URL.createObjectURL(e.target.files[0]));
    } else {
      Swal.fire('Error', 'File harus bertipe jpg/jpeg/png', 'error')
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', mahasiswa.name)
    formData.append('nisn', mahasiswa.nisn)
    formData.append('tempat_lahir', mahasiswa.tempat_lahir)
    formData.append('tanggal_lahir', mahasiswa.tanggal_lahir)
    formData.append('jenis_kelamin', mahasiswa.jenis_kelamin)
    formData.append('agama', mahasiswa.agama?.value)
    formData.append('status_bekerja', mahasiswa.status_bekerja)
    formData.append('nik', mahasiswa.nik)
    formData.append('handphone', mahasiswa.handphone)
    formData.append('telepon', mahasiswa.telepon)
    formData.append('alamat', mahasiswa.alamat)
    formData.append('kelurahan', mahasiswa.kelurahan?.value)
    formData.append('kecamatan', mahasiswa.kecamatan?.value)
    formData.append('kabupaten', mahasiswa.kabupaten?.value)
    formData.append('provinsi', mahasiswa.provinsi?.value)
    formData.append('kode_pos', mahasiswa.kode_pos)
    formData.append('rt', mahasiswa.rt)
    formData.append('rw', mahasiswa.rw)
    if (fileIjazah) {
      formData.append('path_ijazah', fileIjazah)
    }

    let errorValidasi
    delete mahasiswa.path_ijazah
    const validasi = Object.assign(mahasiswa)
    Object.keys(validasi).some(keys => {
      if (validasi[keys] === '') {
        if (keys.search('_') >= 0) {
          errorValidasi = `${keys.split('_').join(' ')} wajib diisi!!!`
        } else {
          errorValidasi = `${keys.charAt(0).toUpperCase() + keys.slice(1)} wajib diisi!!!`
        }
      }
      return validasi[keys] === '' || validasi[keys] === null
    })

    if (errorValidasi) {
      Swal.fire({
        icon: 'error',
        title: errorValidasi,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      const response = await setUpdateProfileMhs(formData)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        const finalResponse = await setUpdateOrtuMhs({
          ...ortu,
          pendidikan_ayah: ortu.pendidikan_ayah?.value,
          pekerjaan_ayah: ortu.pekerjaan_ayah?.value,
          penghasilan_ayah: ortu.penghasilan_ayah?.value,
          pendidikan_ibu: ortu.pendidikan_ibu?.value,
          pekerjaan_ibu: ortu.pekerjaan_ibu?.value,
          penghasilan_ibu: ortu.penghasilan_ibu?.value
        })
        if (finalResponse.error) {
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: response.message
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Profile Berhasil diupdate, silahkan lakukan login ulang!!!'
          }).then(() => {
            Cookies.remove('_T0123')
            Cookies.remove('_R8l')
            Cookies.remove('_P01e')
            history.push('/auth')
          })
        }
      }
    }
  }

  useEffect(() => {
    getDataProvinsi()
    initialData()
  }, [])

  const cookiesProfil = Cookies.get('_P01e')
  const profile = JSON.parse(atob(cookiesProfil))

  return (
    <>
      {loading && <Loader />}
      <ModalPreview
        isOpen={isOpen}
        toggle={() => toggle()}
        data={previewIjazah ? previewIjazah : `${process.env.REACT_APP_IMAGE_URL}images/ijazah/${mahasiswa.path_ijazah}`}
      />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Sunting Profile</h3>
                <p className="mb-0">{profile.name}, NIM: {profile.nim}</p>
              </CardHeader>
              <CardBody>
                <Form onSubmit={submit}>
                  <Row>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Nama</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={mahasiswa.name}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, name: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>NISN</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.nisn}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, nisn: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tempat Lahir</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={mahasiswa.tempat_lahir}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, tempat_lahir: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tanggal Lahir</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="date"
                              value={mahasiswa.tanggal_lahir}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, tanggal_lahir: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Jenis Kelamin</Label>
                            <div className="py-2">
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={mahasiswa.jenis_kelamin === "Laki-laki"}
                                  value="Laki-laki"
                                  onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_kelamin: e.target.value })}
                                />
                                <Label check>
                                  Laki-laki
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={mahasiswa.jenis_kelamin === "Perempuan"}
                                  value="Perempuan"
                                  onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_kelamin: e.target.value })}
                                />
                                <Label check>
                                  Perempuan
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Agama
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="agama"
                              placeholder="Select Agama"
                              value={mahasiswa.agama}
                              options={optionAgama}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, agama: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Status Bekerja</Label>
                            <div className="py-2">
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={mahasiswa.status_bekerja === "Bekerja"}
                                  value="Bekerja"
                                  onChange={(e) => setMahasiswa({ ...mahasiswa, status_bekerja: e.target.value })}
                                />
                                <Label check>
                                  Bekerja
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                              >
                                <Input
                                  type="radio"
                                  checked={mahasiswa.status_bekerja === "Tidak Bekerja"}
                                  value="Tidak Bekerja"
                                  onChange={(e) => setMahasiswa({ ...mahasiswa, status_bekerja: e.target.value })}
                                />
                                <Label check>
                                  Tidak Bekerja
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>NIK</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.nik}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, nik: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Jenis Tinggal</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={mahasiswa.jenis_tinggal}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, jenis_tinggal: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Alat Transportasi</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={mahasiswa.alat_transportasi}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, alat_transportasi: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Alamat</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={mahasiswa.alamat}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, alamat: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>RT</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.rt}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, rt: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>RW</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.rw}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, rw: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Provinsi</Label>
                            <Select
                              className="form-control-alternative"
                              name="provinsi"
                              placeholder="Select Provinsi"
                              value={mahasiswa.provinsi}
                              options={optionProvinsi}
                              onChange={(e) => {
                                setMahasiswa({ ...mahasiswa, provinsi: e, kabupaten: '', kecamatan: '', kelurahan: '' })
                                getDataKabupaten(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Kabupaten / Kota
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="kabupaten"
                              placeholder="Select Kabupaten / Kota"
                              value={mahasiswa.kabupaten}
                              isDisabled={!mahasiswa.provinsi}
                              options={optionKabupaten}
                              onChange={(e) => {
                                setMahasiswa({ ...mahasiswa, kabupaten: e, kecamatan: '' })
                                getDataKecamatan(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>
                              Kecamatan
                            </Label>
                            <Select
                              className="form-control-alternative"
                              name="kecamatan"
                              placeholder="Select Kecamatan"
                              value={mahasiswa.kecamatan}
                              isDisabled={!mahasiswa.kabupaten}
                              options={optionKecamatan}
                              onChange={(e) => {
                                setMahasiswa({ ...mahasiswa, kecamatan: e, kelurahan: '' })
                                getDataKelurahan(e.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Kelurahan / Desa</Label>
                            <Select
                              className="form-control-alternative"
                              name="kelurahan"
                              placeholder="Select Kelurahan"
                              value={mahasiswa.kelurahan}
                              isDisabled={!mahasiswa.kecamatan}
                              options={optionKelurahan}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, kelurahan: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Kewarganegaraan</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              value="Indonesia"
                              disabled
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Kode Pos</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.kode_pos}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, kode_pos: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Telepon</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.telepon}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, telepon: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>No Handphone</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={mahasiswa.handphone}
                              onChange={(e) => setMahasiswa({ ...mahasiswa, handphone: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Orang Tua
                  </h6>
                  <Row>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Nama Ayah</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={ortu.nama_ayah}
                              onChange={(e) => setOrtu({ ...ortu, nama_ayah: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tanggal Lahir Ayah</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="date"
                              value={ortu.tanggal_lahir_ayah}
                              onChange={(e) => setOrtu({ ...ortu, tanggal_lahir_ayah: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>NIK Ayah</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={ortu.nik_ayah}
                              onChange={(e) => setOrtu({ ...ortu, nik_ayah: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Telepon Ayah</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={ortu.telepon_ayah}
                              onChange={(e) => setOrtu({ ...ortu, telepon_ayah: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Pendidikan Ayah</Label>
                            <Select
                              name="pendidikan"
                              className="form-control-alternative"
                              placeholder="Select Pendidikan"
                              options={optionPendidikan}
                              value={ortu.pendidikan_ayah}
                              onChange={(e) => setOrtu({ ...ortu, pendidikan_ayah: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Pekerjaan Ayah</Label>
                            <Select
                              name="pekerjaan"
                              className="form-control-alternative"
                              placeholder="Select Pekerjaan"
                              options={optionPekerjaan}
                              value={ortu.pekerjaan_ayah}
                              onChange={(e) => setOrtu({ ...ortu, pekerjaan_ayah: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Penghasilan Ayah</Label>
                            <Select
                              name="penghasilan"
                              className="form-control-alternative"
                              placeholder="Select Penghasilan"
                              options={optionPenghasilan}
                              value={ortu.penghasilan_ayah}
                              onChange={(e) => setOrtu({ ...ortu, penghasilan_ayah: e })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Nama Ibu</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="text"
                              value={ortu.nama_ibu}
                              onChange={(e) => setOrtu({ ...ortu, nama_ibu: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Tanggal Lahir Ibu</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="date"
                              value={ortu.tanggal_lahir_ibu}
                              onChange={(e) => setOrtu({ ...ortu, tanggal_lahir_ibu: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>NIK Ibu</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={ortu.nik_ibu}
                              onChange={(e) => setOrtu({ ...ortu, nik_ibu: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label>Telepon Ibu</Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Drop some text here..."
                              type="number"
                              value={ortu.telepon_ibu}
                              onChange={(e) => setOrtu({ ...ortu, telepon_ibu: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Pendidikan Ibu</Label>
                            <Select
                              name="pendidikan"
                              className="form-control-alternative"
                              placeholder="Select Pendidikan"
                              options={optionPendidikan}
                              value={ortu.pendidikan_ibu}
                              onChange={(e) => setOrtu({ ...ortu, pendidikan_ibu: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Pekerjaan Ibu</Label>
                            <Select
                              name="pekerjaan"
                              className="form-control-alternative"
                              placeholder="Select Pekerjaan"
                              options={optionPekerjaan}
                              value={ortu.pekerjaan_ibu}
                              onChange={(e) => setOrtu({ ...ortu, pekerjaan_ibu: e })}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="form-custom">
                            <Label>Penghasilan Ibu</Label>
                            <Select
                              name="penghasilan"
                              className="form-control-alternative"
                              placeholder="Select Penghasilan"
                              options={optionPenghasilan}
                              value={ortu.penghasilan_ibu}
                              onChange={(e) => setOrtu({ ...ortu, penghasilan_ibu: e })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="text-danger" hidden={mahasiswa.path_ijazah ? true : false}>*Anda belum upload ijazah</Label>
                        <Label hidden={!mahasiswa.path_ijazah ? true : false}>Update Photo Ijazah</Label>
                        <Input
                          type="file"
                          onChange={(e) => onChangeIjazah(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" hidden={mahasiswa?.path_ijazah ? false : (previewIjazah ? false : true)}>
                      <Button type="button" onClick={() => toggle()}>Preview</Button>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">Simpan Perubahan</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SuntingProfile