import {
  Card,
  CardHeader,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Header from "components/Headers/Header";
import { useState } from "react";
import ListMahasiswa from "./ListMahasiswa";
import ListDosenStaff from "./ListDosenStaff";


const User = () => {

  const [tab, setTab] = useState("1");

  const tabPane = () => {
    return (
      <>
        <TabPane tabId="1">
          <ListMahasiswa />
        </TabPane>
        <TabPane tabId="2">
          <ListDosenStaff />
        </TabPane>
      </>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Nav>
                  <NavItem>
                    <NavLink active={tab === "1"} onClick={() => setTab("1")}>
                      <i className="fas fa-user mr-2" />
                      Mahasiswa
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active={tab === "2"} onClick={() => setTab("2")}>
                      <i className="fa fa-user mr-2" />
                      Dosen / Staff
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <TabContent activeTab={tab}>
                {tabPane()}
              </TabContent>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default User;
